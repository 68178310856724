const BASE = import.meta.env.VITE_API_ENDPOINT;

const serverRoutes = {
  carts: {
    cart(partnerID, email) {
      return `${BASE}/checkout_bff/${partnerID}/${email}`;
    },
    addLineItem(partnerID) {
      return `${BASE}/checkout_bff/${partnerID}/items`;
    },
    removeLineItem(partnerID, itemID) {
      return `${BASE}/checkout_bff/${partnerID}/items/${itemID}`;
    },
    applyCoupon(partnerID) {
      return `${BASE}/checkout_bff/${partnerID}/coupons`;
    },
    removeCoupon(partnerID, code) {
      return `${BASE}/checkout_bff/${partnerID}/coupons/${code}`;
    },
    complete(partnerID) {
      return `${BASE}/checkout_bff/${partnerID}/complete`;
    },
  },

  catalogue: {
    index(qp) {
      return `${BASE}/catalogue/?${qp}`;
    },

    items(_partner_id) {
      return `${BASE}/catalogue/partner/${_partner_id}`;
    },

    post() {
      return `${BASE}/catalogue/items`;
    },

    show(id) {
      return `${BASE}/catalogue/items/${id}`;
    },

    patch(id) {
      return `${BASE}/catalogue/items/${id}`;
    },
  },

  cohorts: {
    index() {
      return `${BASE}/cohorts/`;
    },

    post() {
      return `${BASE}/cohorts/`;
    },

    show(programID) {
      return `${BASE}/cohorts/${programID}`;
    },

    patch(id) {
      return `${BASE}/cohorts/${id}`;
    },
  },

  coupons: {
    post() {
      return `${BASE}/coupons/`;
    },
    index() {
      return `${BASE}/coupons/list`;
    },
    show(id) {
      return `${BASE}/coupons/${id}`;
    },
    validate(id) {
      return `${BASE}/coupons/validate/${id}`;
    },
    patch(id) {
      return `${BASE}/coupons/${id}`;
    },
    delete(id) {
      return `${BASE}/coupons/${id}`;
    },
  },

  marketingLists: {
    search() {
      return `${BASE}/bls/lists`;
    },
    status() {
      return `${BASE}/bls/status`;
    },
    createJob() {
      return `${BASE}/bls/list`;
    },
  },

  applications: {
    post(email, partnerID) {
      return `${BASE}/apps/partner/${partnerID}/email/${email}`;
    },

    patch(email, partnerID) {
      return `${BASE}/apps/partner/${partnerID}/email/${email}`;
    },

    show(email, partnerID) {
      return `${BASE}/apps/partner/${partnerID}/email/${email}`;
    },

    file(key) {
      return `${BASE}/apps/file/${key}`;
    },

    status(status) {
      return `${BASE}/apps/status/${status}`;
    },

    timeline(email, partnerId) {
      return `${BASE}/apps/timeline/email/${email}/partner/${partnerId}`;
    },
  },

  forms: {
    index(partner_id) {
      return `${BASE}/forms/admin/partners/${partner_id}`;
    },

    post() {
      return `${BASE}/forms/admin`;
    },

    show(id) {
      return `${BASE}/forms/admin/${id}`;
    },

    patch(id) {
      return `${BASE}/forms/admin/${id}`;
    },
  },

  inventory: {
    post() {
      return `${BASE}/inventory/items`;
    },

    show(id) {
      return `${BASE}/inventory/items/${id}`;
    },

    patch(id) {
      return `${BASE}/inventory/items/${id}`;
    },

    market() {
      return `${BASE}/inventory/market`;
    },

    migrate() {
      return `${BASE}/ucs/course`;
    },

    fetchJob(id) {
      return `${BASE}/ucs/job/inventory/${id}`;
    },

    delete(id) {
      return `${BASE}/inventory/items/${id}`;
    },

    available(partnerID, catalogueID) {
      return `${BASE}/aai/${partnerID}/${catalogueID}`;
    },

    inventoryByCatalogueID(catalogueID) {
      return `${BASE}/inventory/enroll/${catalogueID}`;
    },

    search() {
      return `${BASE}/inventory/search`;
    },

    enrollments(programID) {
      return `${BASE}/enrollments/${programID}`;
    },
  },

  enrollments: {
    switch(email) {
      return `${BASE}/enrollments/switch/${email}`;
    },
    delete(inventoryId) {
      return `${BASE}/enrollments/${inventoryId}`;
    },
    deleteEnrollment(inventoryId, email) {
      return `${BASE}/enrollments/${inventoryId}/${email}`;
    },
    withdrawEnrollment(inventoryId, email) {
      return `${BASE}/enrollments/${inventoryId}/${email}`;
    },
    activeEnrollments(partnerId, email) {
      return `${BASE}/enrollments/${partnerId}/${email}`;
    },
    lmsEnrollment(partnerId, inventoryId, email) {
      return `${BASE}/ucs/partner/${partnerId}/inventory/${inventoryId}/user/${email}`;
    },
    sections(partnerId, inventoryId) {
      return `${BASE}/ucs/partner/${partnerId}/inventory/${inventoryId}`;
    },
    switchSections(partnerId, inventoryId, email) {
      return `${BASE}/ucs/partner/${partnerId}/inventory/${inventoryId}/user/${email}`;
    },
  },

  mentors: {
    index(qp) {
      return `${BASE}/mentors/?${qp}`;
    },

    post() {
      return `${BASE}/mentors/`;
    },

    show(email) {
      return `${BASE}/mentors/${email}`;
    },

    delete(email) {
      return `${BASE}/mentors/${email}`;
    },

    patch() {
      return `${BASE}/mentors/`;
    },
  },

  qualifiedCatalogueItems: {
    index(email) {
      return `${BASE}/mentors/${email}/qualified`;
    },

    post(email) {
      return `${BASE}/mentors/cat/${email}`;
    },

    patch(email) {
      return `${BASE}/mentors/cat/${email}`;
    },

    delete(email) {
      return `${BASE}/mentors/cat/${email}`;
    },
  },

  partners: {
    index() {
      return `${BASE}/v2/q/partners/all`;
    },

    post() {
      return `${BASE}/v2/c/partners/create`;
    },

    show(id) {
      return `${BASE}/v2/q/partners/${id}`;
    },

    patch(id) {
      return `${BASE}/v2/c/partners/${id}`;
    },

    programs(partnerID) {
      return `${BASE}/programs/partner/${partnerID}`;
    },
  },

  orders: {
    show(id) {
      return `${BASE}/orders/${id}`;
    },
    refund(orderId) {
      return `${BASE}/orders/${orderId}/refund`;
    },
  },

  fulfillment: {
    show(orderId) {
      return `${BASE}/fulfillment/${orderId}`;
    },
  },

  registrations: {
    index(qp) {
      return `${BASE}/registrations?${qp}`;
    },

    post() {
      return `${BASE}/registrations`;
    },

    show(id) {
      return `${BASE}/registrations/${id}`;
    },

    patch(id) {
      return `${BASE}/registrations/${id}`;
    },
  },

  scholarships: {
    index(status) {
      return `${BASE}/scholarships/status/${status}`;
    },

    post() {
      return `${BASE}/scholarships/`;
    },

    show(email, partnerID) {
      return `${BASE}/scholarships/email/${email}/partner/${partnerID}`;
    },

    patch() {
      return `${BASE}/scholarships/email`;
    },

    cohorts(id) {
      return `${BASE}/programs/${id}/cohorts`;
    },

    changeStatus() {
      return `${BASE}/scholarships/status`;
    },

    queue() {
      return `${BASE}/scholarships/queue`;
    },

    status(status) {
      return `${BASE}/scholarships/status/${status}`;
    },

    timeline(email, partnerId) {
      return `${BASE}/scholarships/timeline/email/${email}/partner/${partnerId}`;
    },
  },

  status: {
    marketing() {
      return `${BASE}/status/marketing`;
    },

    internal() {
      return `${BASE}/status/internal`;
    },
  },

  profiles: {
    show(partnerId, email) {
      return `${BASE}/profiles/${partnerId}/${email}`;
    },
  },

  mentorSearch: {
    search() {
      return `${BASE}/mentors/search`;
    },
    show(email) {
      return `${BASE}/mentors/${email}`;
    },
    list(catalogueID) {
      return `${BASE}/mentors/list/cat/${catalogueID}`;
    },
  },

  mentorAssignment: {
    index() {
      return `${BASE}/mentor/assignments`;
    },
    list(inventoryId) {
      return `${BASE}/mentors/list/inv/${inventoryId}`;
    },
    assign(email) {
      return `${BASE}/mentors/inv/${email}`;
    },
    unassign(email) {
      return `${BASE}/mentors/inv/${email}`;
    },
  },
};

export default serverRoutes;
