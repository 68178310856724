import { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const SearchForm = function ({ onSubmit }) {
  const [searchString, setsearchString] = useState("");

  const onChange = function (e) {
    setsearchString(e.target.value);
  };

  const handleSubmit = function (e) {
    e.preventDefault();
    onSubmit(searchString);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          p: 2,
        }}
      >
        <TextField
          fullWidth
          label="Keyword"
          name="email"
          onChange={onChange}
          size="small"
          value={searchString}
          variant="outlined"
        />
        <Button
          color="neutral"
          type="submit"
          variant="outlined"
          sx={{ flexShrink: 0 }}
        >
          Search
        </Button>
      </Box>
    </form>
  );
};

export default SearchForm;
