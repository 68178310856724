import * as PropTypes from "prop-types";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { INVENTORY_STATUS_OPTIONS } from "@/constants";

const StatusSelect = function ({ value = "", ...rest }) {
  return (
    <div>
      <InputLabel id="status">Status</InputLabel>
      <Select
        label="Status"
        defaultValue={value ?? ""}
        name="status"
        size="small"
        value={value ?? ""}
        sx={{ width: "100%" }}
        {...rest}
      >
        {INVENTORY_STATUS_OPTIONS.map((stat) => {
          return (
            <MenuItem key={stat.label} value={stat.value}>
              {stat.label}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

StatusSelect.propTypes = {
  value: PropTypes.string,
};

export default StatusSelect;
