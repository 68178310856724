import requestOptions from "@/lib/request-options";
import routes from "@/lib/server-routes";

export function formatForServer(data) {
  return {
    ...data,
  };
}

export function formatForClient(data) {
  return {
    ...data,
  };
}

function propertiesOrDefaults(data) {
  return {
    ...data,
    name: data.name || "",
    domain: data.domain || "",
    linkSiteRebuild: data.linkSiteRebuild || "",
    linkLMS: data.linkLMS || "",
    onlineName: data.onlineName || "",
    canvasAccountId: data.canvasAccountId || "",
    canvasAdminToken: data.canvasAdminToken || "",
    supportEmail: data.supportEmail || "",
    published: data.published || false,
  };
}

export const fetchPartners = async function () {
  const resp = await window.auth.client.get(routes.partners.index()).json();
  return resp.map(formatForClient);
};

export const fetchPartner = async function ({ queryKey }) {
  const id = queryKey[1];
  const resp = await window.auth.client.get(routes.partners.show(id)).json();
  return formatForClient(resp);
};

export async function savePartner(data) {
  const { url, method } = requestOptions("partners", data._id);
  const resp = await window.auth.client[method](url, {
    json: formatForServer(data),
  }).json();

  return formatForClient(resp);
}

const partner = {
  propertiesOrDefaults,
  fetchPartners,
  fetchPartner,
  savePartner,
};

export default partner;
