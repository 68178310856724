import { AnimatePresence, motion } from "framer-motion";
import { Question } from "phosphor-react";
import { useState } from "react";
import { useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import ActionButtons from "@/components/ActionButtons";
import { FORM_PAPER_HEADING_SX, FORM_PAPER_SX } from "@/constants";
import Coupon from "@/models/coupon";

import CreateCouponCode from "./Form/CreateCouponCode";
import SelectAdjustmentType from "./Form/SelectAdjustmentType";
import SetAmountOff from "./Form/SetAmountOff";
import SetExpirationDate from "./Form/SetExpirationDate";
import SetMaxUses from "./Form/SetMaxUses";
import SetScope from "./Form/SetScope";

const CouponForm = ({
  cancel,
  onSubmit,
  couponData,
  doesCodeExist,
  isSaving,
}) => {
  const [showScopeHelp, setShowScopeHelp] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      ...Coupon.propertiesOrDefaults(couponData),
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <SelectAdjustmentType
          scopedUsers={getValues("scopedUsers")}
          partner={getValues("partner")}
          setValue={setValue}
          errors={errors}
          control={control}
        />
      </Paper>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        {doesCodeExist && (
          <>
            <Typography fontWeight="bold">Code</Typography>
            <Typography>
              <code>{getValues("code")}</code>
            </Typography>
          </>
        )}
        {!doesCodeExist && (
          <CreateCouponCode
            setValue={setValue}
            hasCustomCode={getValues("hasCustomCode")}
            control={control}
            watch={watch}
          />
        )}
      </Paper>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <SetAmountOff setValue={setValue} control={control} watch={watch} />
      </Paper>
      <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
        Expiration
      </Typography>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <SetExpirationDate control={control} />
        <br />
        <br />
        <SetMaxUses control={control} />
      </Paper>
      <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
        Scope
      </Typography>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <Box
          as="button"
          onClick={() => {
            setShowScopeHelp(!showScopeHelp);
          }}
          sx={(theme) => ({
            display: "flex",
            gap: 0.75,
            alignItems: "center",
            border: 0,
            background: "transparent",
            mb: 2,
            p: 0,
            opacity: 0.7,
            cursor: "pointer",
            color: "#222",
            ...theme.applyStyles("dark", {
              color: "#eee",
            }),
          })}
          type="button"
        >
          <Question size={21} weight="fill" />
          <Typography
            as="span"
            variant="body2"
            sx={{
              textDecoration: "underline",
            }}
          >
            How does scope work?
          </Typography>
        </Box>
        {showScopeHelp ? (
          <AnimatePresence>
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              style={{ overflow: "hidden" }}
            >
              <Typography as="ul" variant="body2" sx={{ mb: 1, opacity: 0.75 }}>
                <li>
                  Setting the scope will limit the coupon to apply to only the
                  scoped items. Not setting any scope will apply the coupon to
                  the entire cart.
                </li>
                <li>
                  Scoping the email addresses will limit the coupon to apply to
                  those students. Not scoping to any emails allows the coupon to
                  be used by anyone.
                </li>
                <li>
                  Users will only be allowed to apply a single
                  coupon/scholarship and it will be the users responsibility to
                  decide which coupon gives them the best deal. Coupons will
                  never allow the cart to go below 0.
                </li>
              </Typography>

              <Typography variant="body2" sx={{ mb: 3, opacity: 0.75 }}>
                If you have any questions please contact leon.gersing@kaplan.com
                for a tutorial.
              </Typography>
            </motion.div>
          </AnimatePresence>
        ) : null}
        <SetScope
          setValue={setValue}
          partnerID={getValues("partner._id")}
          scopedUsers={getValues("scopedUsers")}
          watch={watch}
        />
      </Paper>
      <br />
      <ActionButtons cancel={cancel} isSaving={isSaving} />
    </form>
  );
};

export default CouponForm;
