import { useEffect, useState } from "react";
import * as yup from "yup";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import PartnerSelect from "@/components/PartnerSelect";
import Spinner from "@/components/Spinner";
import useSelectedPartnerID from "@/hooks/use-selected-partner-id";

function SearchForm({ onSubmit, partners, urlEmail, urlPartnerID }) {
  const [selectedPartnerID, setSelectedPartnerID] =
    useSelectedPartnerID(urlPartnerID);
  const [noEmail, setNoEmail] = useState(false);

  useEffect(() => {
    if (urlPartnerID) {
      setSelectedPartnerID(urlPartnerID);
    }
  }, [setSelectedPartnerID, urlPartnerID]);

  const onChange = function (event) {
    if (event.target.value !== "") {
      setNoEmail(false);
    }
  };

  const handleSubmit = function (event) {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const isEmail = yup
      .string()
      .required()
      .email()
      .isValidSync(data.get("email"));
    if (!isEmail) {
      setNoEmail(true);
      return;
    }
    data.set(
      "partner_id",
      `${selectedPartnerID}` === "0" ? "all" : selectedPartnerID,
    );

    onSubmit(Object.fromEntries(data));
  };
  return (
    <Box
      as="form"
      onSubmit={handleSubmit}
      sx={{ display: { sm: "flex" }, p: 2, gap: 1, alignItems: "flex-start" }}
    >
      {partners.isLoading ? (
        <Box
          sx={{
            border: "1px dashed rgba(153,153,153, 0.6)",
            borderRadius: 1.5,
            px: 2,
            py: 1,
            opacity: 0.8,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Spinner loading={true} sx={{ mr: 1 }} /> Loading partners
        </Box>
      ) : null}
      {partners.data && (
        <FormControl sx={{ width: "40%", flexGrow: 1 }}>
          <PartnerSelect
            allPartners={true}
            onChange={(e) => {
              setSelectedPartnerID(e.target.value);
            }}
            partners={partners.data}
            required
            value={selectedPartnerID}
          />
        </FormControl>
      )}
      <Box sx={{ width: "60%", flexGrow: 1 }}>
        <TextField
          label="Email address"
          name="email"
          onChange={onChange}
          size="small"
          defaultValue={urlEmail}
          variant="outlined"
          fullWidth={true}
          error={noEmail}
          helperText={noEmail ? "Please enter an email." : ""}
          slotProps={{
            inputLabel: { shrink: true },
          }}
        />
      </Box>
      <Button
        color="neutral"
        type="submit"
        variant="outlined"
        sx={{ flexShrink: 0 }}
      >
        Search
      </Button>
    </Box>
  );
}

export default SearchForm;
