import Box from "@mui/material/Box";

import { Drawer, DrawerManager } from "@/components/Drawer";
import GeneralTimeline from "@/components/Timeline";

export default DrawerManager.create(function ({
  email,
  partner,
  fetchTimeline,
}) {
  return (
    <Drawer title="Timeline">
      <Box sx={{ p: 2, bgcolor: "background.paper" }}>
        <GeneralTimeline
          email={email}
          partnerID={partner}
          fetchTimeline={fetchTimeline}
        />
      </Box>
    </Drawer>
  );
});
