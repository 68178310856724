import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import Chip from "@/components/Chip";
import { INVENTORY_STATUS_COLOR_MAP } from "@/constants";
import { centsToPrice, formatDate } from "@/lib/helpers";

import MentorsTableCellContents from "./MentorsTableCellContents";

const SearchResultsTable = function ({ inventoryItems, handleAssign }) {
  const transformedItem = (item) => {
    const formattedRetailPrice = centsToPrice(item.retailPrice);
    return { ...item, retailPrice: formattedRetailPrice };
  };
  return (
    <TableBody>
      {inventoryItems.map((item) => {
        return (
          <TableRow key={item._id} hover>
            <TableCell>
              {item.lmsEnrollmentRequested ? (
                <CheckIcon color="success" />
              ) : null}
            </TableCell>
            <TableCell>
              <Chip label={item.partnerName} size="small" />
            </TableCell>
            <TableCell>
              <Chip
                sx={{
                  height: "auto",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                  },
                }}
                label={item.catalogueItemName}
              />
            </TableCell>
            <TableCell>{formatDate(item.startDate)}</TableCell>
            <TableCell>{item.cohortLength}</TableCell>
            <TableCell>
              <Chip
                color={INVENTORY_STATUS_COLOR_MAP[item.status]}
                label={item.status}
                size="small"
              />
            </TableCell>
            <TableCell align="right">
              <Stack
                sx={{ minWidth: "max-content" }}
                alignItems="center"
                flexDirection="row"
                justifyContent="flex-start"
              >
                {item.redemptionCount} / {item.maxCapacity}
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection="column" spacing={1}>
                <MentorsTableCellContents inventoryItem={item} />
              </Stack>
            </TableCell>
            <TableCell align="right">
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  handleAssign(transformedItem(item));
                }}
              >
                Assign
              </Button>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default SearchResultsTable;
