import * as PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyToClipboardController = function CopyToClipboardController({
  children,
  text,
}) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
      {children(copied)}
    </CopyToClipboard>
  );
};

CopyToClipboardController.propTypes = {
  children: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default CopyToClipboardController;
