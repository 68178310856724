import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";

import TitleBar from "@/components/TitleBar";

import SideBarList from "./SideBarList";

const SideBar = ({
  applications,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  setAppDetails,
  supTitle,
}) => {
  return (
    <>
      <TitleBar
        loading={applications.isLoading}
        title="Applications"
        supTitle={supTitle}
      />
      {applications.data ? (
        <SideBarList
          applications={applications}
          setAppDetails={setAppDetails}
        />
      ) : null}
      {applications.isError ? (
        <div style={{ textAlign: "center" }}>
          ⚠️ <em>Could not fetch applications</em>
        </div>
      ) : null}
      <Box
        sx={(theme) => ({
          position: "sticky",
          bottom: 0,
          bgcolor: "rgb(255,255,255)",
          borderTop: "1px solid #e7e5f5",
          px: { xs: 7, md: 2 },
          py: 2,
          zIndex: 2,
          "@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)":
            {
              backdropFilter: "blur(6px)",
              bgcolor: "rgba(255,255,255, 0.6)",
              ...theme.applyStyles("dark", {
                bgcolor: "rgba(18,18,18, 0.6)",
              }),
            },
          ...theme.applyStyles("dark", {
            bgcolor: "rgb(18,18,18)",
            borderTop: "1px solid #333",
          }),
        })}
      >
        <LoadingButton
          disabled={!hasNextPage}
          loading={isFetchingNextPage}
          onClick={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
          sx={{ width: "100%" }}
          variant="contained"
        >
          Load More
        </LoadingButton>
      </Box>
    </>
  );
};

export default SideBar;
