import { useQuery } from "@tanstack/react-query";
import * as PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";

// Material UI
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

// KUP
import ActionButtons from "@/components/ActionButtons";
import FormErrorMessage from "@/components/FormErrorMessage";
import { FORM_PAPER_SX, INVENTORY_TYPE_MAP } from "@/constants";
import Catalogue from "@/models/catalogue";
import Inventory from "@/models/inventory";

import MaxCapacity from "./MaxCapacity";
import ProductDates from "./ProductDates";

const InventoryFormEdit = function InventoryFormEdit(props) {
  const { isSaving, onSubmit, saveError } = props;
  const data = props.data;
  const _id = data.catalogueItem._id;

  const catalogueItem = useQuery({
    queryKey: ["catalogue", _id],
    queryFn() {
      return Catalogue.findByID(_id);
    },
  });

  const formatMSRP = () => {
    const { msrp } = Catalogue.propertiesOrDefaults(catalogueItem.data);
    return msrp;
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...Inventory.propertiesOrDefaults(data),
    },
  });

  const catalogueItems = useQuery({
    queryKey: ["catalogue", data.partner._id],
    queryFn() {
      return Catalogue.findAllByPartnerID(data.partner._id);
    },
    enabled: Boolean(data.partner._id),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {data.partner.name}
          </Grid>
          <Grid item xs={12}>
            Type: {INVENTORY_TYPE_MAP[catalogueItem.data?.type]}
          </Grid>
          <Grid item xs={12}>
            {data.catalogueItem.name}
          </Grid>
        </Grid>
      </Paper>
      <br />
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: "inline", mr: 2 }}>
              <MaxCapacity control={control} errors={errors} />
            </Box>
            <FormControl margin="normal">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Controller
                  control={control}
                  name="retailPrice"
                  render={({ field: { ref, ...rest } }) => {
                    return (
                      <TextField
                        error={errors.retailPrice ? true : false}
                        inputRef={ref}
                        label="Retail Price"
                        required
                        size="small"
                        sx={{ flexGrow: 1, maxWidth: "11rem" }}
                        variant="outlined"
                        {...rest}
                      />
                    );
                  }}
                  rules={{ required: "This field is required" }}
                />
              </div>
              {catalogueItem.data && (
                <FormHelperText>MSRP: ${formatMSRP()} USD</FormHelperText>
              )}
              <FormErrorMessage error={errors["retailPrice"]} />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      {catalogueItems.data && catalogueItem.data ? (
        <>
          <ProductDates
            control={control}
            errors={errors}
            type={catalogueItem.data?.type}
          />
        </>
      ) : null}

      <ActionButtons
        cancel={props.cancel}
        error={saveError}
        isSaving={isSaving}
      >
        <FormErrorMessage gutterBottom error={saveError} />
      </ActionButtons>
    </form>
  );
};

InventoryFormEdit.propTypes = {
  cancel: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  saveError: PropTypes.string,
  successCallback: PropTypes.func,
};

export default InventoryFormEdit;
