import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { TWO_COLUMN_STYLE } from "@/routes/Applications/style";

import Heading from "../Heading";

const yesNo = function (bool) {
  return bool ? "yes" : "no";
};

const StudentLegalAndAddress = ({ student }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const TWO_COL = matches
    ? TWO_COLUMN_STYLE
    : {
        ...TWO_COLUMN_STYLE,
        display: "block",
      };

  const LI = matches ? { margin: 0 } : { margin: "0 0 1rem 0" };

  return (
    <>
      <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
        Student Legal
      </Typography>

      <ul style={TWO_COL}>
        <li style={LI} key="canResident">
          <Heading>Canada Resident</Heading>
          {yesNo(student.canResident)}
        </li>
        <li style={LI} key="euResident">
          <Heading>European Union (EU) Resident</Heading>
          {yesNo(student.euResident)}
        </li>
        <li style={LI} key="tou-pp-accept">
          <Heading>{`Terms Of Use & Privacy Policy Accept`}</Heading>
          {yesNo(student.touppAccept)}
        </li>
        <li style={LI} key="partner-tou-pp-accept">
          <Heading>{`Partner Terms Of Use & Privacy Policy Accept`}</Heading>
          {yesNo(student.partnerTouppAccept)}
        </li>
        <li style={LI} key="tcpaConsent">
          <Heading>Student TCPA Consent</Heading>
          {yesNo(student.tcpaConsent)}
        </li>
        <li style={LI} key="crossPartnerMarketing">
          <Heading>Cross Partner Marketing Opt-in</Heading>
          {yesNo(student.crossPartnerMarketingOptIn)}
        </li>
      </ul>

      <br />
      <br />

      <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
        Student Address
      </Typography>

      <ul style={TWO_COL}>
        <li style={LI} key="studentPostalCode">
          <Heading>Student Zip/Postal Code</Heading>
          {student.address?.zipCode}
        </li>
        <li style={LI} key="studentCountry">
          <Heading>Student Country</Heading>
          {student.address?.country}
        </li>
      </ul>

      <ul style={TWO_COL}>
        <li style={{ gridColumn: "span 2" }}>
          <TableContainer>
            <Table>
              <TableBody>
                {student.address?.region && (
                  <TableRow key="studentMailingInternationalRegion">
                    <TableCell size="small" sx={{ opacity: 0.7 }}>
                      Student International Region:
                    </TableCell>
                    <TableCell size="small">
                      {student.address?.region}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </li>
      </ul>
    </>
  );
};

export default StudentLegalAndAddress;
