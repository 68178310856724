import * as PropTypes from "prop-types";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

export const ErrorMessage = function (props) {
  const text = props.text ? props.text : "Error loading data";
  return props.error ? (
    <Alert severity="error" style={{ marginBottom: "0.5rem" }}>
      {text}
    </Alert>
  ) : null;
};

ErrorMessage.propTypes = {
  error: PropTypes.any,
  text: PropTypes.string,
};

export const LoadingMessage = function ({ loading, text = "Loading" }) {
  return loading ? (
    <Alert
      icon={<CircularProgress size="1.25rem" />}
      severity="info"
      style={{ marginBottom: "0.5rem" }}
    >
      {text}
    </Alert>
  ) : null;
};

LoadingMessage.propTypes = {
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string,
};
