import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import Chip from "@/components/Chip";

import { HeadingLoading, StatusLoading } from "./Loading";

const DetailsHeader = ({ inventoryItem, seatsFilled }) => {
  const mqSmallAndUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <Box
      sx={[
        mqSmallAndUp
          ? {
              display: "flex",
            }
          : {
              display: "block",
            },
      ]}
    >
      {inventoryItem.isLoading ? <HeadingLoading /> : null}
      {inventoryItem.data && (
        <div style={{ flexGrow: 1 }}>
          <Typography
            variant="body1"
            sx={{ fontWeight: 400, lineHeight: "1.4", opacity: 0.75 }}
          >
            {inventoryItem.data.partner.name}
            <br />
            {`${inventoryItem.data.catalogueItem.name} `}
            {inventoryItem.data.catalogueItem.type === "addOn" ? (
              <Chip label="addOn" variant="outlined" />
            ) : null}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              wordWrap: "break-word",
              overflowWrap: "break-word",
              padding: 0,
            }}
          >
            {inventoryItem.data.name}
          </Typography>
        </div>
      )}
      <div style={{ flexShrink: 0 }}>
        <Card variant="outlined" sx={{ minWidth: "200px", mt: 1 }}>
          <CardContent sx={{ p: 2, paddingBottom: "16px !important" }}>
            <Typography noWrap color="textSecondary">
              Seats Filled
            </Typography>
            <Typography
              variant="h5"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {inventoryItem.data ? (
                <>
                  {seatsFilled} / {inventoryItem.data.maxCapacity}
                </>
              ) : (
                <StatusLoading />
              )}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </Box>
  );
};

export default DetailsHeader;
