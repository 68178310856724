import { Flag, Warning } from "phosphor-react";
import { useParams } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Chip from "@/components/Chip";
import Data from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import ProfileDrawer from "@/components/Drawers/ProfileDrawer";
import ScholarshipDrawer from "@/components/Drawers/ScholarshipDrawer";
import TimelineDrawer from "@/components/Drawers/TimelineDrawer";
import { STATUS_COLOR_MAP, STATUS_MAP } from "@/constants";
import { formatDate } from "@/lib/helpers";
import { fetchTimeline } from "@/models/applications";

import AcademicDetails from "./AppDisplayComponents/AcademicDetails";
import ChangeApplicationStatus from "./AppDisplayComponents/ChangeApplicationStatus";
import EssayAndFileTab from "./AppDisplayComponents/EssayAndFileTab";
import GuardianDetails from "./AppDisplayComponents/GuardianDetails";
import InventoryItemDetails from "./AppDisplayComponents/InventoryItemDetails";
import StudentLegalAndAddress from "./AppDisplayComponents/StudentLegalAndAddress";
import StudentNameAndContact from "./AppDisplayComponents/StudentNameAndContact";

export default function ApplicationShowView({
  app,
  isSaving,
  profile,
  scholarship,
  saveApplication,
}) {
  const profileDrawer = useDrawer(ProfileDrawer);
  const scholarshipDrawer = useDrawer(ScholarshipDrawer);
  const timelineDrawer = useDrawer(TimelineDrawer);
  const { partner, email } = useParams();

  const cohorts = app.data?.selections.map((sel, index) => {
    return (
      <InventoryItemDetails inventoryId={sel.inventoryItem._id} key={index} />
    );
  });

  const showScholarship = function () {
    scholarshipDrawer.show({
      profile,
      scholarship,
      successCallback() {
        scholarshipDrawer.remove();
      },
    });
  };

  const showTimeline = function () {
    timelineDrawer.show({
      email: email || profile.data.email || scholarship.data.author,
      partner:
        partner || profile.data.partner._id || scholarship.data.partner._id,
      fetchTimeline,
    });
  };

  return (
    <div>
      <Typography
        as="h2"
        display="flex"
        variant="h5"
        sx={{ alignItems: "center" }}
      >
        {profile.isLoading ? (
          <>
            <span style={{ opacity: 0.5 }}>Loading applicant</span>
            <IconButton style={{ opacity: 0 }} onClick={() => {}}>
              <VisibilityIcon />
            </IconButton>
          </>
        ) : profile.data ? (
          <>
            {`${profile.data.firstName} ${profile.data.lastName}`}
            <IconButton
              onClick={() => {
                profileDrawer.show({
                  email: profile.data.email,
                  partnerID: profile.data.partner._id,
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </>
        ) : null}
      </Typography>

      <Typography as="h2" display="block" marginBottom={true} variant="h5">
        {app.data?.partner.name}
      </Typography>

      {app.data?.flagged ? (
        <div>
          {app.data?.flagSeverity === "red" ? (
            <Flag
              color="#d00"
              size="24"
              style={{ marginRight: "0.5rem" }}
              weight="fill"
            />
          ) : (
            <Warning
              color="#FFB81C"
              size="24"
              style={{ marginRight: "0.5rem" }}
              weight="fill"
            ></Warning>
          )}
        </div>
      ) : null}

      <br />

      <Data>
        <Data.Item>
          <Data.Label>Application Status:</Data.Label>
          <Data.Content>
            {app.data ? (
              <Chip
                color={STATUS_COLOR_MAP[app.data.status]}
                label={STATUS_MAP[app.data.status]}
                size="small"
              />
            ) : (
              <em>none</em>
            )}
          </Data.Content>
        </Data.Item>
        <Data.Item>
          <Data.Label>Scholarship Status:</Data.Label>
          <Data.Content>
            {scholarship.data ? (
              <Chip
                color={STATUS_COLOR_MAP[scholarship.data.status]}
                label={STATUS_MAP[scholarship.data.status]}
                size="small"
              />
            ) : (
              <em>none</em>
            )}
          </Data.Content>
          {scholarship.data && (
            <IconButton onClick={showScholarship} size="small">
              <VisibilityIcon />
            </IconButton>
          )}
        </Data.Item>
        <Data.Item>
          <Data.Label>Timeline:</Data.Label>
          <Data.Content>
            <IconButton onClick={showTimeline} size="small">
              <VisibilityIcon />
            </IconButton>
          </Data.Content>
        </Data.Item>
        {app.data?.completedAt ? (
          <Data.Item>
            <Data.Label>Application Completed:</Data.Label>
            <Data.Content>{formatDate(app.data.completedAt)}</Data.Content>
          </Data.Item>
        ) : null}
      </Data>

      <br />

      {cohorts ? (
        <Stack spacing={2} marginBottom={2}>
          {cohorts}
        </Stack>
      ) : null}

      <Typography variant="h6" sx={{ mb: 2 }}>
        Application Status
      </Typography>
      {app.data && (
        <ChangeApplicationStatus
          application={app.data}
          isSaving={isSaving}
          save={(data) => {
            saveApplication(data);
          }}
        />
      )}

      <br />
      <br />

      {app.data?.student ? (
        <>
          <StudentNameAndContact student={app.data.student} />
          {app.data && (
            <EssayAndFileTab
              appData={app.data}
              isSaving={isSaving}
              save={saveApplication}
            />
          )}

          <br />
          <br />

          <StudentLegalAndAddress student={app.data.student} />
        </>
      ) : null}

      <br />
      <br />

      {app.data?.guardian ? (
        <GuardianDetails guardian={app.data.guardian} />
      ) : (
        <em>No guardian information</em>
      )}

      <br />
      <br />

      {app.data?.education ? (
        <AcademicDetails school={app.data.education} />
      ) : (
        <div>
          <em>No academic information</em>
        </div>
      )}
    </div>
  );
}
