import { useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

export default function ActiveCoupon({ coupon, isRemoving, remove }) {
  const [confirm, setConfirm] = useState(false);

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <IconButton
        aria-label="remove item"
        color="warning"
        disabled={isRemoving}
        onClick={() => {
          setConfirm(!confirm);
        }}
      >
        <ClearIcon />
      </IconButton>
      <Typography as="div" sx={{ flexGrow: 1 }} variant="body1">
        <code>{coupon.code}</code>
        <br />
        {confirm ? (
          <div className="animation-fade-in">
            <Button
              color="neutral"
              disabled={isRemoving}
              onClick={() => {
                setConfirm(false);
              }}
              size="small"
            >
              Cancel
            </Button>
            <LoadingButton
              color="error"
              loading={isRemoving}
              onClick={remove}
              size="small"
            >
              Yes, remove
            </LoadingButton>
          </div>
        ) : (
          <>
            {Object.hasOwn(coupon, "errors") ? (
              <>
                {coupon.errors.map((err, i) => (
                  <div style={{ color: "#d00", fontWeight: "bold" }} key={i}>
                    {err.message}
                  </div>
                ))}
              </>
            ) : (
              <span>{coupon.formattedAmount} off</span>
            )}
          </>
        )}
      </Typography>
    </Box>
  );
}
