import routes from "@/lib/server-routes";

function formatForServer(data) {
  return {
    ...data,
  };
}

function formatForClient(data) {
  return {
    ...data,
  };
}

function propertiesOrDefaults(data) {
  return {
    ...data,
    email: data.email || "",
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    scholarshipStatus: data.scholarshipStatus || "",
    enrollments: data.enrollments || [],
    orders: data.orders || [],
  };
}

export const fetchProfile = async function ({ queryKey }) {
  const partnerId = queryKey[1];
  const email = queryKey[2];
  const url = routes.profiles.show(partnerId, email);
  const resp = await window.auth.client.get(url).json();
  return formatForClient(resp);
};

const profile = {
  propertiesOrDefaults,
  fetchProfile,
  formatForServer,
};

export default profile;
