import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useDrawer } from "@/components/Drawer";
import EnrollmentsDropDrawer from "@/components/Drawers/EnrollmentsDropDrawer";
import FormErrorMessage from "@/components/FormErrorMessage";
import {
  FORM_PAPER_HEADING_SX,
  FORM_PAPER_SX,
  REFUND_REASONS_OPTIONS,
} from "@/constants";
import useAlert from "@/hooks/use-alert";

import Enrollments from "./Enrollments";

const RefundForm = function RefundForm(props) {
  const { isSaving, saveError, onSubmit } = props;
  const data = props.data || {};
  const [showConfirmRefund, setShowConfirmRefund] = useState(false);
  const showAlert = useAlert();
  const dropsDrawer = useDrawer(EnrollmentsDropDrawer);
  const queryClient = useQueryClient();

  const { handleSubmit, setValue, getValues, watch } = useForm({
    defaultValues: {
      refund: {
        notes: "",
        isDrop: true,
        reason: "cancellation",
        scopedItems: [],
      },
    },
  });

  const selectedItems = watch("refund.scopedItems");
  const [localIsDrop, setLocalIsDrop] = useState(true);
  const [localNotes, setLocalNotes] = useState("");
  const [localReason, setLocalReason] = useState("cancellation");

  const openDropsDrawer = function () {
    dropsDrawer.show({
      cancel() {
        dropsDrawer.remove();
      },
      data: { ...data, scopedItems: getValues("refund.scopedItems") },
      email: data.student.email,
      errorCallback() {
        showAlert({
          message: `An error has ocurred!`,
          severity: "error",
          duration: 10000,
        });
      },
      successCallback() {
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ["orders", data._id],
          });
        }, 1000);
        showAlert({
          message: "Successfully processed enrollments",
          duration: 4000,
        });
        dropsDrawer.remove();
      },
    });
  };

  const handleOpenDropsDrawer = () => {
    if (localIsDrop === true && localReason !== "other") {
      return openDropsDrawer();
    }
    if (localIsDrop === true && localReason === "other" && localNotes !== "") {
      return openDropsDrawer();
    }
    return null;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
        Order Details
      </Typography>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <Box sx={{ flexGrow: 1 }}>
          {data.lineItems.map((lineItem) => {
            return (
              <Grid container spacing={2} key={lineItem._id}>
                <Grid item xs={9}>
                  <Typography variant="body1">
                    {lineItem.catalogueItem.name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" align="right">
                    {lineItem.formattedPriceToPay}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
          <br />
          <Grid container spacing={2} key="total">
            <Grid item xs={9}>
              <Typography variant="body1" fontWeight="bold">
                Order Total:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight="bold" align="right">
                {data.summary.formattedPriceToPay}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
        Refund Reason
      </Typography>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <Box>
          <Grid container spacing={2} key="total">
            <Grid item xs={7} alignItems="center" display="grid">
              <Typography variant="body1">
                Does this refund contain a drop?
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <FormControl required>
                <RadioGroup
                  row
                  defaultValue={getValues("refund.isDrop")}
                  onChange={(event) => {
                    setValue("refund.isDrop", event.target.value);
                    setLocalIsDrop(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                    key="yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                    key="no"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Grid item xs={12}>
            <FormControl size="small" sx={{ width: "100%" }}>
              <InputLabel id="refund-reason-select-field-label">
                Reason for Refund
              </InputLabel>
              <Select
                disabled={isSaving}
                defaultValue={getValues("refund.reason")}
                onChange={(event) => {
                  setValue("refund.reason", event.target.value);
                  setLocalReason(event.target.value);
                }}
                labelId="refund-reason-select-field-label"
                size="small"
                required
              >
                {REFUND_REASONS_OPTIONS.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl margin="normal" size="small" sx={{ width: "100%" }}>
              <TextField
                label="Add a reason for this refund"
                defaultValue={getValues("refund.notes")}
                size="small"
                onChange={(event) => {
                  setValue("refund.notes", event.target.value);
                  setLocalNotes(event.target.value);
                }}
                required={localReason === "other"}
              ></TextField>
              <FormHelperText>
                A note is required when the provided reason is “Other”
              </FormHelperText>
            </FormControl>
          </Grid>
        </Box>
      </Paper>
      <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
        Line Items
      </Typography>
      <Enrollments data={data.lineItems} setValue={setValue} />
      <Box
        sx={(theme) => ({
          position: "sticky",
          width: "100%",
          bottom: 0,
          borderTop: "1px solid",
          borderColor: "#e7e5f5",
          paddingTop: 2,
          paddingRight: 2,
          paddingBottom: 2,
          paddingLeft: 2,
          backgroundColor: "background.default",
          zIndex: 2,
          ...theme.applyStyles("dark", {
            borderColor: "#333",
          }),
        })}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          {showConfirmRefund ? (
            <LoadingButton
              color="primary"
              loading={isSaving}
              sx={{
                flexGrow: 1,
                marginRight: 2,
                minWidth: "7rem",
              }}
              type="submit"
              variant="contained"
              className="animation-fade-in-slow"
              onClick={() => handleOpenDropsDrawer()}
            >
              Confirm Refund
            </LoadingButton>
          ) : null}

          <Button
            disabled={!selectedItems.length}
            onClick={() => {
              setShowConfirmRefund(true);
            }}
            sx={{ mr: 1 }}
            variant={showConfirmRefund ? "outlined" : "contained"}
          >
            Submit
          </Button>

          <Button
            color="neutral"
            onClick={props.cancel}
            sx={{
              marginRight: 2,
            }}
            type="button"
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>

        <FormErrorMessage error={saveError} />
      </Box>
    </form>
  );
};
export default RefundForm;
