import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Box from "@mui/material/Box";

import { Drawer, DrawerManager, useDrawer } from "@/components/Drawer";
import CouponNewDrawer from "@/components/Drawers/CouponFormDrawer";
import { PS_COUPON_DEFAULTS } from "@/constants";
import useAlert from "@/hooks/use-alert";
import { fetchApplication } from "@/models/applications";
import { fetchScholarship, saveScholarship } from "@/models/scholarship";
import ShowView from "@/routes/Scholarships/components/ShowView";

const DataLoad = function ({ children, email, partnerID }) {
  const application = useQuery({
    queryKey: ["applications", email, partnerID],
    queryFn: fetchApplication,
  });
  const scholarship = useQuery({
    queryKey: ["scholarships", email, partnerID],
    queryFn: fetchScholarship,
  });
  return children({ application, scholarship });
};

export default DrawerManager.create(function ({ profile, successCallback }) {
  const queryClient = useQueryClient();
  const showAlert = useAlert();
  const couponFormDrawer = useDrawer(CouponNewDrawer);
  const scholarship = useQuery({
    queryKey: ["scholarships", profile.data.email, profile.data.partner?._id],
    queryFn: fetchScholarship,
  });

  const updateMutation = useMutation({
    mutationFn: saveScholarship,
    onSuccess(savedData) {
      showAlert({
        message: `Scholarship updated`,
      });
      queryClient.invalidateQueries({
        queryKey: ["scholarships"],
      });
      if (savedData.status === "approved") {
        couponFormDrawer.show({
          cancel() {
            couponFormDrawer.remove();
          },
          data: {
            ...PS_COUPON_DEFAULTS,
            partner: {
              _id: scholarship.data.partner._id,
              name: scholarship.data.partner.name,
            },
            scopedUsers: [profile.data.email],
          },
          errorCallback(error) {
            if (error.message.includes("409")) {
              showAlert({
                message: `Error! This coupon code already exists!`,
                severity: "error",
                duration: 10000,
              });
            } else {
              showAlert({
                message: `Error! An error has ocurred`,
                severity: "error",
                duration: 10000,
              });
            }
          },
          successCallback(data) {
            showAlert({
              message: `Successfully created coupon with code: ${data.code}`,
              duration: null,
            });
            couponFormDrawer.remove();
          },
        });
      }
      if (successCallback) {
        successCallback(savedData);
      }
    },
  });

  return (
    <Drawer loading={updateMutation.isPending} title="Scholarship">
      {profile.data && (
        <DataLoad
          email={profile.data.email}
          partnerID={profile.data.partner?._id}
        >
          {({ application, scholarship }) => {
            return (
              <Box sx={{ p: 2, bgcolor: "background.paper" }}>
                {scholarship.isPending && <div>Loading...</div>}
                {scholarship.data && (
                  <ShowView
                    application={application}
                    isSaving={updateMutation.isPending}
                    profile={profile}
                    scholarship={scholarship}
                    saveScholarship={(newData) => {
                      updateMutation.mutate(newData);
                    }}
                  />
                )}
              </Box>
            );
          }}
        </DataLoad>
      )}
    </Drawer>
  );
});
