import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ArrowUp, Notebook } from "phosphor-react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

import { useDrawer } from "@/components/Drawer";
import CatalogueFormDrawer from "@/components/Drawers/CatalogueFormDrawer";
import EmptyIndexMessage from "@/components/EmptyIndexMessage";
import MainDetail from "@/components/MainDetail";
import PartnerSelect from "@/components/PartnerSelect";
import TitleBar from "@/components/TitleBar";
import TitleBarButton from "@/components/TitleBarButton";
import useAlert from "@/hooks/use-alert";
import useSelectedPartnerID from "@/hooks/use-selected-partner-id";
import clientRoutes from "@/lib/client-routes";
import { useCatalogueForPartner } from "@/models/catalogue/hooks";
import { fetchPartners } from "@/models/partner";

import MainList from "./components/MainList";
import CatalogueShow from "./show";

const Main = function ({ catalogueItems, partners, showNewForm }) {
  const [selectedPartnerID, setSelectedPartnerID] = useSelectedPartnerID();
  return (
    <>
      <TitleBar
        loading={partners.isLoading || catalogueItems.isLoading}
        title="Catalogue"
      >
        {partners.data && (
          <TitleBarButton
            disabled={!selectedPartnerID}
            icon="add"
            onClick={showNewForm}
          >
            New Item
          </TitleBarButton>
        )}
      </TitleBar>
      <Box sx={{ mr: 2, ml: 2 }}>
        {partners.data && (
          <>
            <FormControl sx={{ maxWidth: "100%", minWidth: "100%" }}>
              <PartnerSelect
                onChange={(e) => {
                  setSelectedPartnerID(e.target.value);
                }}
                partners={partners.data}
                value={selectedPartnerID}
              />
            </FormControl>
            {!selectedPartnerID && (
              <div style={{ marginTop: "", textAlign: "center" }}>
                <ArrowUp className="bounce" size={40} weight="light" />
              </div>
            )}
          </>
        )}
        {catalogueItems.data && !catalogueItems.data.length && (
          <div
            style={{
              marginTop: "2rem",
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            No Catalogue items
          </div>
        )}
      </Box>
      {catalogueItems.data && <MainList items={catalogueItems.data} />}
    </>
  );
};

const Empty = function () {
  return (
    <EmptyIndexMessage
      icon={<Notebook size={100} weight="thin" />}
      text="Select a catalogue item"
    />
  );
};

export default function CatalogueRoute() {
  const queryClient = useQueryClient();
  const showAlert = useAlert();
  const newItemDrawer = useDrawer(CatalogueFormDrawer);
  const editItemDrawer = useDrawer(CatalogueFormDrawer);
  const [selectedPartnerID] = useSelectedPartnerID();

  // Data Fetching -------------------------------------------------------------

  const partners = useQuery({
    queryKey: ["partners"],
    queryFn: fetchPartners,
  });
  const catalogueItems = useCatalogueForPartner(selectedPartnerID);
  const navigate = useNavigate();

  // Event Handlers ------------------------------------------------------------

  const showNewForm = function () {
    newItemDrawer.show({
      cancel() {
        newItemDrawer.remove();
      },
      data: {
        partner_id: selectedPartnerID,
      },
      partners,
      successCallback(data) {
        queryClient.invalidateQueries({
          queryKey: ["catalogue"],
        });
        showAlert({ message: "Catalogue item created!" });
        navigate(clientRoutes.catalogue.show(data._id));
        newItemDrawer.remove();
      },
      errorCallback(error) {
        console.log(error);
        showAlert({ message: "An error has occurred", severity: "error" });
      },
    });
  };

  const handleEdit = function (data) {
    editItemDrawer.show({
      cancel() {
        editItemDrawer.remove();
      },
      data: {
        ...data,
        partner_id: data.partner._id,
      },
      partners,
      successCallback() {
        queryClient.invalidateQueries({
          queryKey: ["catalogue"],
        });
        showAlert({
          message: "Catalogue item saved!",
        });
        editItemDrawer.remove();
      },
      errorCallback(error) {
        console.log(error);
        showAlert({ message: "An error has occurred", severity: "error" });
      },
    });
  };

  return (
    <>
      <MainDetail
        MainComponent={Main}
        mainProps={{
          catalogueItems,
          partners,
          selectedPartnerID,
          showNewForm,
        }}
        DetailComponent={CatalogueShow}
        detailProps={{ edit: handleEdit }}
        detailPath={clientRoutes.catalogue.showPath}
        EmptyComponent={Empty}
      />
    </>
  );
}
