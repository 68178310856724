import Box from "@mui/material/Box";

function EmptyBox({ children, sx = {} }) {
  return (
    <Box
      sx={{
        border: "1px dashed rgba(153,153,153, 0.6)",
        borderRadius: 1.5,
        px: 2,
        opacity: 0.8,
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export default EmptyBox;
