import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/system/Box";

import CellLink from "@/components/CellLink";
import clientRoutes from "@/lib/client-routes";

export default function Results({ profiles }) {
  const arrayOfProfiles = Object.values(profiles);
  const theme = useTheme();

  return (
    <List
      sx={{
        bgcolor: "background.paper",
        position: "relative",
        overflow: "auto",
        maxHeight: "60vh",
        padding: 0,
        borderBottomRightRadius: "0.5rem",
        borderBottomLeftRadius: "0.5rem",
        "& ul": { padding: 0 },
        "& .MuiListSubheader-root": { lineHeight: "32px" },
      }}
    >
      <ListItem disablePadding>
        <Box sx={{ width: "100%", marginBottom: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#f3f3f3",
                  ...theme.applyStyles("dark", {
                    backgroundColor: "#222",
                  }),
                }}
              >
                <TableCell>Partner</TableCell>
                <TableCell>Profile Name</TableCell>
                <TableCell>Email address</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arrayOfProfiles.map((profile, index) => {
                const url = clientRoutes.profiles.show(
                  profile.partner._id,
                  profile.email,
                );
                return (
                  <TableRow key={index} hover>
                    <TableCell>
                      <CellLink url={url} />
                      {profile.partner.name}
                    </TableCell>
                    <TableCell>
                      <CellLink url={url} />
                      {`${profile.firstName} ${profile.lastName}`}
                    </TableCell>
                    <TableCell>
                      <CellLink url={url} />
                      {profile.email}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </ListItem>
    </List>
  );
}
