import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  THREE_COLUMN_STYLE,
  TWO_COLUMN_STYLE,
} from "@/routes/Applications/style";

import Heading from "../Heading";

const StudentNameAndContact = ({ student }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const THREE_COL = matches
    ? THREE_COLUMN_STYLE
    : {
        ...THREE_COLUMN_STYLE,
        display: "block",
      };
  const TWO_COL = matches
    ? TWO_COLUMN_STYLE
    : {
        ...TWO_COLUMN_STYLE,
        display: "block",
      };

  const LI = matches ? { margin: 0 } : { margin: "0 0 1rem 0" };

  return (
    <>
      <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
        Student Name
      </Typography>
      <ul style={THREE_COL}>
        <li style={LI} key="studentFirstName">
          <Heading>First Name</Heading>
          {student.firstName}
        </li>
        <li style={LI} key="studentMiddleName">
          <Heading>Middle Name</Heading>
          {student.middleName ?? "—"}
        </li>
        <li style={LI} key="studentLastName">
          <Heading>Last Name</Heading>
          {student.lastName}
        </li>
      </ul>

      <br />
      <br />

      <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
        Student Contact
      </Typography>

      <ul style={TWO_COL}>
        <li style={LI} key="studentEmail">
          <Heading>Student Email</Heading>
          {student.email}
        </li>
        <li style={LI} key={"studentPhone"}>
          <Heading>Student Phone Number</Heading>
          {student.phone}
        </li>
      </ul>

      <br />
      <br />
    </>
  );
};

export default StudentNameAndContact;
