import Button from "@mui/material/Button";

import { ActionButtonsView } from "@/components/ActionButtons";
import { Drawer, DrawerManager } from "@/components/Drawer";
import MentorQualifiedCoursesForm from "@/components/MentorQualifiedCoursesForm";

import type { TMentorQualifiedCatalogueItem } from "@/models/mentor/types";

export default DrawerManager.create(function ({
  cancel,
  email,
  items,
  onSuccess,
}: {
  cancel: () => void;
  email: string;
  items: Array<TMentorQualifiedCatalogueItem>;
  onSuccess: () => void;
}) {
  return (
    <Drawer close={cancel} title="Edit Qualified Catalogue Items">
      <MentorQualifiedCoursesForm
        cancel={cancel}
        email={email}
        items={items}
        onSuccess={onSuccess}
      />
      <ActionButtonsView>
        <Button
          color="neutral"
          onClick={cancel}
          type="button"
          variant="outlined"
        >
          Close
        </Button>
      </ActionButtonsView>
    </Drawer>
  );
});
