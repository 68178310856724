import { ShoppingCart } from "phosphor-react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

import EmptyIndexMessage from "@/components/EmptyIndexMessage";
import Layout from "@/components/Layout";
import TitleBar from "@/components/TitleBar";
import clientRoutes from "@/lib/client-routes";
import { useOrder } from "@/models/orders";

import SearchBar from "./components/SearchBar";
import Show from "./show";

const OrderLoad = function ({ children }) {
  const params = useParams();
  const order = useOrder(params._id);
  return children({ order });
};

const OrdersLayout = function ({ children, submitSearch }) {
  const params = useParams();
  return (
    <>
      <TitleBar title="Orders" />
      <SearchBar onSubmit={submitSearch} UrlID={params._id} />
      <Layout.Content>{children}</Layout.Content>
    </>
  );
};

const OrdersRoute = function () {
  const navigate = useNavigate();

  const submitSearch = (orderID) => {
    navigate(clientRoutes.orders.show(orderID));
  };

  return (
    <Layout>
      <Routes>
        <Route
          index
          element={
            <OrdersLayout submitSearch={submitSearch}>
              <EmptyIndexMessage
                icon={<ShoppingCart size={100} weight="thin" />}
                text="Perform a search to view an order"
              />
            </OrdersLayout>
          }
        />
        <Route
          element={
            <OrdersLayout submitSearch={submitSearch}>
              <OrderLoad>{({ order }) => <Show order={order} />}</OrderLoad>
            </OrdersLayout>
          }
          path={clientRoutes.orders.showPath}
        />
      </Routes>
    </Layout>
  );
};

export default OrdersRoute;
