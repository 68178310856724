import { useQuery } from "@tanstack/react-query";
import { Student } from "phosphor-react";
import { useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

import EmptyIndexMessage from "@/components/EmptyIndexMessage";
import Layout from "@/components/Layout";
import TitleBar from "@/components/TitleBar";
import clientRoutes from "@/lib/client-routes";
import { fetchPartners } from "@/models/partner";

import SearchForm from "./components/SearchForm";
import SearchResults from "./components/SearchResults";
import ProfileShow from "./show";

const GetProfileInfo = function ({ children }) {
  const params = useParams();
  return children({ ...params });
};

const ProfilesLayout = function ({ children, submitSearch }) {
  const partners = useQuery({
    queryKey: ["partners"],
    queryFn: fetchPartners,
  });
  const params = useParams();

  return (
    <>
      <TitleBar title="Profiles" />
      <SearchForm
        onSubmit={submitSearch}
        partners={partners}
        urlEmail={params.email}
        urlPartnerID={params.partnerId}
      />
      <Layout.Content>{children}</Layout.Content>
    </>
  );
};

const ProfilesIndexRoute = function () {
  const navigateTo = useNavigate();
  const [searchSubmitted, setSearchSubmitted] = useState(true);

  const submitSearch = (data) => {
    setSearchSubmitted(true);
    navigateTo(clientRoutes.profiles.show(data.partner_id, data.email));
  };

  return (
    <Layout>
      <Routes>
        <Route
          index
          element={
            <ProfilesLayout submitSearch={submitSearch}>
              <EmptyIndexMessage
                icon={<Student size={100} weight="thin" />}
                text="Perform a search to view a profile"
              />
            </ProfilesLayout>
          }
        />
        <Route
          element={
            <ProfilesLayout submitSearch={submitSearch}>
              <SearchResults showResults={searchSubmitted} />
            </ProfilesLayout>
          }
          path={clientRoutes.profiles.showAllPath}
        />
        <Route
          element={
            <ProfilesLayout submitSearch={submitSearch}>
              <GetProfileInfo>
                {({ email, partnerId }) => (
                  <ProfileShow email={email} partnerID={partnerId} />
                )}
              </GetProfileInfo>
            </ProfilesLayout>
          }
          path={clientRoutes.profiles.showPath}
        />
      </Routes>
    </Layout>
  );
};

export default ProfilesIndexRoute;
