import routes from "@/lib/server-routes";

function propertiesOrDefaults(data) {
  return {
    ...data,
  };
}

export const fetchScholarshipsByStatus = async function (status, cursor) {
  const url = routes.scholarships.status(status);
  const resp = await window.auth.client
    .post(url, {
      json: {
        cursor,
      },
    })
    .json();
  return resp;
};

export const fetchTimeline = async function ({ queryKey }) {
  const email = queryKey[1];
  const partnerId = queryKey[2];
  const url = routes.scholarships.timeline(email, partnerId);
  const resp = await window.auth.client.get(url).json();
  return resp;
};

export const fetchScholarship = async function ({ queryKey }) {
  const email = queryKey[1];
  const partnerID = queryKey[2];
  const url = routes.scholarships.show(email, partnerID);
  const resp = await window.auth.client.get(url).json();
  return resp;
};

export async function saveScholarship(data) {
  const url = routes.scholarships.patch();
  const resp = await window.auth.client
    .patch(url, {
      json: data,
    })
    .json();

  return resp;
}

const scholarship = {
  propertiesOrDefaults,
  fetchScholarship,
  saveScholarship,
  fetchScholarshipsByStatus,
  fetchTimeline,
};

export default scholarship;
