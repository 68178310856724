import { useMutation } from "@tanstack/react-query";

import { Drawer, DrawerManager } from "@/components/Drawer";
import PartnerForm from "@/components/PartnerForm";
import { savePartner } from "@/models/partner";

export default DrawerManager.create(function ({
  cancel,
  data,
  successCallback,
}) {
  const mutation = useMutation({
    mutationFn: savePartner,
    onSuccess(data) {
      if (successCallback) {
        successCallback(data);
      }
    },
  });

  return (
    <Drawer loading={mutation.isPending} title="New Partner">
      <PartnerForm
        cancel={cancel}
        data={data}
        isSaving={mutation.isPending}
        onSubmit={mutation.mutate}
        saveError={mutation.isError ? mutation.error.message : null}
      />
    </Drawer>
  );
});
