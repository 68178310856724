import * as PropTypes from "prop-types";

import Typography from "@mui/material/Typography";

const TableCaption = function TableCaption({ children, title }) {
  return (
    <Typography variant="h5" component="h2" gutterBottom>
      {children ? children : title}
    </Typography>
  );
};

TableCaption.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default TableCaption;
