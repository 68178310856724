import routes from "@/lib/server-routes";

export const fetchFulfillment = async function ({ queryKey }) {
  const id = queryKey[1];
  const url = routes.fulfillment.show(id);
  const resp = await window.auth.client.get(url).json();
  return resp;
};

const fulfillment = {
  fetchFulfillment,
};

export default fulfillment;
