import { Link } from "react-router-dom";

const CellLink = function ({ url }) {
  return (
    <Link
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
      }}
      to={url}
    />
  );
};

export default CellLink;
