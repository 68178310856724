import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Box from "@mui/material/Box";

import { Drawer, DrawerManager } from "@/components/Drawer";
import useAlert from "@/hooks/use-alert";
import { fetchApplication, saveApplication } from "@/models/applications";
import { fetchScholarship } from "@/models/scholarship";
import ShowView from "@/routes/Applications/components/ShowView";

const DataLoad = function ({ children, email, partnerID }) {
  const application = useQuery({
    queryKey: ["applications", email, partnerID],
    queryFn: fetchApplication,
  });
  const scholarship = useQuery({
    queryKey: ["scholarships", email, partnerID],
    queryFn: fetchScholarship,
  });
  return children({ application, scholarship });
};

export default DrawerManager.create(function ({ profile, successCallback }) {
  const queryClient = useQueryClient();
  const showAlert = useAlert();
  const mutation = useMutation({
    mutationFn: saveApplication,
    onSuccess(data) {
      queryClient.invalidateQueries({
        queryKey: [
          "applications",
          profile.data.email,
          profile.data.partner._id,
        ],
      });
      if (successCallback) {
        successCallback(data);
      }
    },
    onError(error) {
      console.log(error);
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  return (
    <Drawer loading={mutation.isPending} title="Application">
      {profile.data && (
        <DataLoad
          email={profile.data.email}
          partnerID={profile.data.partner._id}
        >
          {({ application, scholarship }) => {
            return (
              <Box sx={{ p: 2, bgcolor: "background.paper" }}>
                {application.isLoading && <div>Loading...</div>}
                {application.data && (
                  <ShowView
                    app={application}
                    isSaving={mutation.isPending}
                    profile={profile}
                    scholarship={scholarship}
                    saveApplication={(newValues) => {
                      mutation.mutate(newValues);
                    }}
                  />
                )}
              </Box>
            );
          }}
        </DataLoad>
      )}
    </Drawer>
  );
});
