import { useEffect, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";

import { EditTextNotepad } from "@/components/EditTextNotepad";

const camelToSpaces = function (str) {
  return str.replace(/[a-z]/gi, (m, o) =>
    m < {} && o ? ` ${m}` : o ? m : m.toUpperCase(),
  );
};

const BLACKLIST_KEYS = [
  "_id",
  "author",
  "createdAt",
  "completedAt",
  "meta",
  "message",
  "notes",
  "partner",
  "time",
  "statement",
  "status",
];

const HEADING_STYLEZ = {
  borderBottom: "1px solid #ccc",
  opacity: "0.7",
};

const ScholarshipRequest = function ({ scholarship, essay, isSaving, save }) {
  const [statement, setStatement] = useState(scholarship.statement);

  // This forces the view to update when navigating between scholarships
  useEffect(() => {
    setStatement(scholarship.statement || null);
  }, [scholarship]);

  const handleSave = function () {
    const newData = { ...scholarship };

    if (statement !== scholarship.statement) {
      newData.statement = statement;
    }

    save(newData);
  };

  const handleStatementChange = (e) => {
    setStatement(e.target.value);
  };

  return (
    <>
      {scholarship && (
        <>
          <ul
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "2rem",
              listStyle: "none",
              margin: 0,
              padding: 0,
            }}
          >
            {Object.entries(scholarship).map(function ([key, value]) {
              if (BLACKLIST_KEYS.includes(key)) {
                return null;
              }

              if (!value) {
                return null;
              }

              return (
                <li
                  key={key}
                  style={{
                    margin: 0,
                  }}
                >
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={HEADING_STYLEZ}
                  >
                    {camelToSpaces(key)}
                  </Typography>
                  {value}
                </li>
              );
            })}

            <li style={{ gridColumn: "span 2" }}>
              <EditTextNotepad
                color="purple"
                onChange={handleStatementChange}
                title="Scholarship Essay"
                value={statement}
              />

              <br />

              <div>
                <LoadingButton
                  color="primary"
                  loading={isSaving}
                  onClick={handleSave}
                  sx={{ minWidth: "6rem" }}
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </div>
            </li>
            <li style={{ gridColumn: "span 2" }}>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                style={HEADING_STYLEZ}
              >
                Application Essay
              </Typography>
              {essay ? essay : <em>No Essay Submitted</em>}
            </li>
          </ul>

          <br />
        </>
      )}
    </>
  );
};

export default ScholarshipRequest;
