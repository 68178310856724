import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type { Variant } from "@mui/material/styles/createTypography";
import type { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography";
import type { OverridableStringUnion } from "@mui/types";
import type { ReactNode } from "react";

type VariantType = OverridableStringUnion<
  "inherit" | Variant,
  TypographyPropsVariantOverrides
>;

const Data = function Data({ children, ...rest }: { children: ReactNode }) {
  return (
    <div className="data" {...rest}>
      {children}
    </div>
  );
};

Data.Item = function DataItem({ children, ...rest }: { children: ReactNode }) {
  return <Box {...rest}>{children}</Box>;
};

Data.Label = function DataLabel({
  children,
  variant = "body2",
  sx,
  ...rest
}: {
  children: ReactNode;
  sx?: Record<string, unknown>;
  variant?: VariantType;
  [x: string]: unknown;
}) {
  return (
    <Typography
      component="div"
      display="block"
      sx={{ mr: 1, fontWeight: 600, ...sx }}
      variant={variant}
      {...rest}
    >
      {children}
    </Typography>
  );
};

Data.Content = function DataContent({
  children,
  variant = "body2",
  ...rest
}: {
  children: ReactNode;
  variant?: VariantType;
  [x: string]: unknown;
}) {
  return (
    <Typography component="div" variant={variant} {...rest}>
      {children}
    </Typography>
  );
};

export default Data;
