import * as PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import ActionButtons from "@/components/ActionButtons";
import FormErrorMessage from "@/components/FormErrorMessage";
import { FORM_PAPER_HEADING_SX, FORM_PAPER_SX } from "@/constants";
import Partner from "@/models/partner";

const PartnerForm = function PartnerForm(props) {
  const data = props.data || {};
  const isSaving = props.isSaving;
  const saveError = props.saveError;

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: Partner.propertiesOrDefaults(data),
  });

  const requiredWhenPublished = function (value) {
    if (getValues("published")) {
      return value ? true : "This field is required";
    }

    return true;
  };

  return (
    <form onSubmit={handleSubmit(props.onSubmit)} noValidate>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                control={control}
                name="name"
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      error={errors.name ? true : false}
                      inputRef={ref}
                      label="Partner Name"
                      required
                      size="small"
                      variant="outlined"
                      {...rest}
                    />
                  );
                }}
                rules={{ required: "This field is required" }}
              />
              <FormErrorMessage error={errors["name"]} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                name="onlineName"
                control={control}
                rules={{
                  validate: {
                    requiredWhenPublished,
                  },
                }}
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      error={Boolean(errors.onlineName)}
                      inputRef={ref}
                      label="Online Name"
                      required
                      size="small"
                      variant="outlined"
                      {...rest}
                    />
                  );
                }}
              />
              <FormErrorMessage error={errors["onlineName"]} />
              <FormHelperText>
                Branded partner name. Example: Partner University Online Program
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                control={control}
                name="domain"
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      error={errors.domain ? true : false}
                      inputRef={ref}
                      label="Domain"
                      required
                      size="small"
                      variant="outlined"
                      {...rest}
                    />
                  );
                }}
                rules={{
                  validate: {
                    requiredWhenPublished,
                  },
                }}
              />
              <FormErrorMessage error={errors["domain"]} />
              <FormHelperText>
                Example: https://partner.precollegeprograms.org
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                name="supportEmail"
                control={control}
                rules={{
                  validate: {
                    requiredWhenPublished,
                  },
                }}
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      error={Boolean(errors.supportEmail)}
                      inputRef={ref}
                      label="Support Email"
                      required
                      size="small"
                      variant="outlined"
                      {...rest}
                    />
                  );
                }}
              />
              <FormErrorMessage error={errors["supportEmail"]} />
              <FormHelperText component="div">
                When we need to tell the user how to contact this brand via our
                channels.
                <br />
                Example: support@precollegeonline.[partner].edu
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <Controller
                name="published"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        color="primary"
                        inputRef={ref}
                        name={name}
                        onBlur={onBlur}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    }
                    label="Published"
                    labelPlacement="end"
                  />
                )}
              />
              <FormHelperText>
                New applications will be blocked when the partner is unpublished
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
        Integrations
      </Typography>

      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <Typography variant="body1" fontWeight="bold" component="h4">
          Vercel
        </Typography>
        <Grid container marginBottom={2} spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                name="linkSiteRebuild"
                control={control}
                rules={{
                  validate: {
                    requiredWhenPublished,
                  },
                }}
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      error={Boolean(errors.linkSiteRebuild)}
                      inputRef={ref}
                      label="Marketing site rebuild webhook"
                      required
                      size="small"
                      variant="outlined"
                      {...rest}
                    />
                  );
                }}
              />
              <FormErrorMessage error={errors["linkSiteRebuild"]} />
            </FormControl>
          </Grid>
        </Grid>

        <Typography variant="body1" fontWeight="bold" component="h4">
          Canvas
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                name="canvasAccountId"
                control={control}
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      inputRef={ref}
                      label="Canvas Account ID"
                      size="small"
                      variant="outlined"
                      {...rest}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                name="canvasAdminToken"
                control={control}
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      inputRef={ref}
                      label="Canvas Admin Token"
                      size="small"
                      variant="outlined"
                      {...rest}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                name="linkLMS"
                control={control}
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      inputRef={ref}
                      label="Link to Canvas"
                      size="small"
                      variant="outlined"
                      {...rest}
                    />
                  );
                }}
              />
              <FormHelperText>
                Example: https://www.instructure.com/canvas
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      <br />

      <ActionButtons cancel={props.cancel} isSaving={isSaving}>
        <FormErrorMessage gutterBottom error={saveError} />
      </ActionButtons>
    </form>
  );
};

PartnerForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  saveError: PropTypes.object,
  successCallback: PropTypes.func,
};

export default PartnerForm;
