import { centsToPrice, priceToCents } from "@/lib/helpers";
import routes from "@/lib/server-routes";

function formatForServer(data) {
  return {
    ...data,
    price: priceToCents(data.price),
    priceAtEnrollment: priceToCents(data.priceAtEnrollment),
  };
}

function formatForClient(data) {
  return {
    ...data,
    price: centsToPrice(data.price),
    priceAtEnrollment: centsToPrice(data.priceAtEnrollment),
  };
}

function propertiesOrDefaults(data) {
  return {
    ...data,
    cohortId: data.cohortId,
    guardianEmail: data.guardianEmail || "",
    guardianName: data.guardianName || "",
    guardianPhone: data.guardianPhone || "",
    isOutsidePayer: data.isOutsidePayer,
    partnerId: data.partnerId,
    partnerName: data.partnerName || data.partner?.name,
    price: data.price || "",
    priceAtEnrollment: "",
    programId: data.programId,
    programName: data.programName || data.program?.name,
    studentName: data.studentName || "",
    studentEmail: data.studentEmail || "",
  };
}

export const fetchCohortEnrollments = async function ({ queryKey }) {
  const id = queryKey[1];
  const url = routes.inventory.enrollments(id);
  const resp = await window.auth.client.get(url).json();
  return formatForClient(resp);
};

export const deleteEnrollment = async function (
  data,
  inventoryId,
  studentEmail,
) {
  const url = routes.enrollments.deleteEnrollment(inventoryId, studentEmail);
  const resp = await window.auth.client
    .delete(url, {
      json: data,
    })
    .json();

  return resp;
};

export const withdrawEnrollment = async function (
  data,
  inventoryId,
  studentEmail,
) {
  const url = routes.enrollments.withdrawEnrollment(inventoryId, studentEmail);
  const resp = await window.auth.client
    .patch(url, {
      json: data,
    })
    .json();

  return resp;
};

export const fetchActiveEnrollments = async function ({ queryKey }) {
  const partnerId = queryKey[1];
  const email = queryKey[2];
  const url = routes.enrollments.activeEnrollments(partnerId, email);
  const resp = await window.auth.client.get(url).json();
  return resp;
};

export const fetchLmsEnrollment = async function ({ queryKey }) {
  const { partnerId, inventoryId, email } = queryKey[1];
  const url = routes.enrollments.lmsEnrollment(partnerId, inventoryId, email);
  const resp = await window.auth.client.get(url).json();
  return resp;
};

export const fetchLmsSections = async function ({ queryKey }) {
  const { partnerId, inventoryId } = queryKey[1];
  const url = routes.enrollments.sections(partnerId, inventoryId);
  const resp = await window.auth.client.get(url).json();
  return resp;
};

export const switchLmsSections = async function (
  { partnerId, inventoryId, email },
  data,
) {
  const url = routes.enrollments.switchSections(partnerId, inventoryId, email);
  const resp = await window.auth.client.patch(url, { json: data }).json();
  return resp;
};

const enrollment = {
  propertiesOrDefaults,
  fetchCohortEnrollments,
  fetchActiveEnrollments,
  formatForServer,
  deleteEnrollment,
};

export default enrollment;
