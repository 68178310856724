import { useQuery } from "@tanstack/react-query";

import routes from "@/lib/server-routes";

export function serialize(data) {
  return {
    ...data,
  };
}

function propertiesOrDefaults(data) {
  return {
    ...data,
  };
}

export const fetchOrder = async function (id) {
  const url = routes.orders.show(id);
  const resp = await window.auth.client.get(url).json();
  return resp;
};

export const refundOrder = async function (data, orderID) {
  const url = routes.orders.refund(orderID);
  const resp = await window.auth.client
    .post(url, {
      json: data,
    })
    .json();

  return resp;
};

export const dropEnrollment = async function (email, data) {
  const url = routes.enrollments.delete(email);
  const resp = await window.auth.client
    .delete(url, {
      json: data,
    })
    .json();

  return resp;
};
export const switchEnrollment = async function (email, data) {
  const url = routes.enrollments.switch(email);
  const resp = await window.auth.client
    .post(url, {
      json: data,
    })
    .json();

  return resp;
};

export const useOrder = (orderId) => {
  return useQuery({
    queryKey: ["orders", orderId],
    queryFn: ({ queryKey }) => {
      return fetchOrder(queryKey[1]);
    },
  });
};

const order = {
  serialize,
  propertiesOrDefaults,
  fetchOrder,
  switchEnrollment,
};

export default order;
