import { useQuery } from "@tanstack/react-query";

import type { TCatalogueItem } from "./types";
import type { UseQueryResult } from "@tanstack/react-query";

import Catalogue from "./";

export function useCatalogueForPartner(
  partnerID,
): UseQueryResult<TCatalogueItem[], Error> {
  return useQuery({
    queryKey: ["catalogue", { partner_id: partnerID }],
    queryFn() {
      return Catalogue.findAllByPartnerID(partnerID);
    },
    enabled: Boolean(partnerID) && partnerID !== "0",
  });
}
