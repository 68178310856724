import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";

export default function Spinner({ loading = false, sx = {} }) {
  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? "400ms" : "0ms",
      }}
      unmountOnExit
    >
      <CircularProgress color="secondary" size="1rem" sx={sx} />
    </Fade>
  );
}
