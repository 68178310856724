import requestOptions from "@/lib/request-options";
import routes from "@/lib/server-routes";

export const fetchAppsByStatus = async function (status, cursor) {
  const url = routes.applications.status(status);
  const resp = await window.auth.client
    .post(url, {
      json: {
        cursor,
      },
    })
    .json();
  return resp;
};

export const fetchApplication = async function ({ queryKey }) {
  const email = queryKey[1];
  const partnerID = queryKey[2];
  const url = routes.applications.show(email, partnerID);
  const resp = await window.auth.client.get(url).json();
  return resp;
};

export const fetchFileURL = async function ({ queryKey }) {
  const key = queryKey[1];
  const url = routes.applications.file(key);
  const resp = await window.auth.client.get(url).json();
  return resp;
};

export const fetchTimeline = async function ({ queryKey }) {
  const email = queryKey[1];
  const partnerId = queryKey[2];
  const url = routes.applications.timeline(email, partnerId);
  const resp = await window.auth.client.get(url).json();
  return resp;
};

export async function saveApplication(data) {
  const { method, url } = requestOptions(
    "applications",
    data.student.email,
    data.partner._id,
  );
  const resp = await window.auth.client[method](url, {
    json: data,
  }).json();

  return resp;
}

const application = {
  saveApplication,
  fetchAppsByStatus,
  fetchApplication,
  fetchFileURL,
  fetchTimeline,
};

export default application;
