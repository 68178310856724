import { useMutation } from "@tanstack/react-query";

import { Drawer, DrawerManager } from "@/components/Drawer";
import InventoryFormEdit from "@/components/InventoryForm/InventoryFormEdit";
import useAlert from "@/hooks/use-alert";
import { saveInventoryItem } from "@/models/inventory";

export default DrawerManager.create(function ({
  cancel,
  data,
  successCallback,
}) {
  const showAlert = useAlert();
  const mutation = useMutation({
    mutationFn: saveInventoryItem,
    onSuccess(data) {
      if (successCallback) {
        successCallback(data);
      }
    },
    onError(error) {
      console.log(error);
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  return (
    <Drawer loading={mutation.isPending} title="Edit Inventory Item">
      <InventoryFormEdit
        cancel={cancel}
        data={data}
        isSaving={mutation.isPending}
        onSubmit={(newValues) => {
          mutation.mutate(newValues);
        }}
        saveError={mutation.error}
      />
    </Drawer>
  );
});
