import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import CellLink from "@/components/CellLink";
import TableCaption from "@/components/TableCaption";
import clientRoutes from "@/lib/client-routes";

import AssignedTableEmptyRows from "./AssignedTableEmptyRows";

const InventoryTable = ({ assignedInventory = [] }) => {
  return (
    <>
      <TableCaption
        title={`${assignedInventory.length} Assigned Inventory Items`}
      />
      <Table
        size="small"
        className={assignedInventory.length ? "" : "empty-table-body"}
      >
        <TableHead>
          <TableRow>
            <TableCell>Partner</TableCell>
            <TableCell>Inventory Item</TableCell>
            <TableCell>Date/Duration</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assignedInventory.length ? (
            assignedInventory.map((inventoryItem) => {
              const url = clientRoutes.inventorySearch.show(inventoryItem._id);
              return (
                <TableRow key={inventoryItem._id} hover>
                  <TableCell>
                    <CellLink url={url} />
                    <Chip label={inventoryItem.partner.name} size="small" />
                  </TableCell>
                  <TableCell>
                    <CellLink url={url} />
                    <Chip
                      label={inventoryItem.catalogueItem.name}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <CellLink url={url} />
                    {inventoryItem.name}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <AssignedTableEmptyRows />
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default InventoryTable;
