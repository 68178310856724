import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ColorModeSwitcher from "@/components/ColorModeSwitcher";
import Layout from "@/components/Layout";
import TitleBar from "@/components/TitleBar";

const SettingsRoute = function () {
  return (
    <Layout>
      <TitleBar title="Settings" />
      <Layout.Content>
        <br />
        <Typography variant="h6">Appearance</Typography>
        <Stack spacing={2} direction="row" sx={{ p: 1 }}>
          <ColorModeSwitcher />
        </Stack>
      </Layout.Content>
    </Layout>
  );
};

export default SettingsRoute;
