import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { TWO_COLUMN_STYLE } from "../../style";
import Heading from "../Heading";

const GuardianDetails = ({ guardian }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const LI = matches ? { margin: 0 } : { margin: "0 0 1rem 0" };
  const TWO_COL = matches
    ? TWO_COLUMN_STYLE
    : {
        ...TWO_COLUMN_STYLE,
        display: "block",
      };

  return (
    <>
      <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
        Guardian Information
      </Typography>
      <ul style={TWO_COL}>
        <li style={LI} key={"guardianFirstName"}>
          <Heading>First Name</Heading>
          {guardian.firstName}
        </li>
        <li style={LI} key={"guardianLastName"}>
          <Heading>Last Name</Heading>
          {guardian.lastName}
        </li>
      </ul>

      <br />

      <ul style={TWO_COL}>
        <li style={LI} key={"guardianEmail"}>
          <Heading>Guardian Email</Heading>
          {guardian.email}
        </li>
        <li style={LI} key={"guardianPhone"}>
          <Heading>Guardian Phone Number</Heading>
          {guardian.phone}
        </li>
      </ul>
    </>
  );
};

export default GuardianDetails;
