import { useQuery } from "@tanstack/react-query";

import createDate from "@/lib/create-date";
import { centsToPrice, priceToCents } from "@/lib/helpers";
import requestOptions from "@/lib/request-options";
import routes from "@/lib/server-routes";

import { TCatalogueItem } from "./catalogue/types";

import type { UseQueryResult } from "@tanstack/react-query";

export type TInventoryCohortLength =
  | "1 week"
  | "2 weeks"
  | "4 weeks"
  | "6 weeks"
  | "8 weeks"
  | "10 weeks"
  | "12 weeks";

export type TInventoryStatus =
  | "preMarket"
  | "onMarketPublic"
  | "onMarketPrivate"
  | "onboarding"
  | "running"
  | "canceled"
  | "completed";

export type TInventory = {
  _id: string;
  applicationDeadline: string;
  availableForEnrollment: boolean;
  availableForPurchase: boolean;
  availableForPublicMarketing: boolean;
  catalogueItem: Pick<
    TCatalogueItem,
    "_id" | "name" | "msrp" | "type" | "internalName"
  >;
  cohortLength: TInventoryCohortLength;
  createdAt: string;
  endDate: string;
  enrollmentDeadline: string;
  lmsEnrollmentRequested: boolean;
  lmsStartDate: string;
  maxCapacity: number;
  name: string;
  partner: {
    _id: string;
    name: string;
  };
  redemptionCount: number;
  retailPrice: string;
  startDate: string;
  status: TInventoryStatus;
};

function formatForServer(data) {
  return {
    ...data,
    maxCapacity: parseInt(data.maxCapacity),
    retailPrice: priceToCents(data.retailPrice),
  };
}

function formatForClient(data: TInventory): TInventory {
  return {
    ...data,
    retailPrice: centsToPrice(data.retailPrice),
  };
}

function propertiesOrDefaults(data: Partial<TInventory>) {
  return {
    ...data,
    startDate: data.startDate || createDate(0, 1, 0),
    endDate: data.endDate || createDate(28, 1, 0),
    applicationDeadline: data.applicationDeadline || createDate(-7, 1, 0),
    enrollmentDeadline: data.enrollmentDeadline || createDate(-2, 1, 0),
    maxCapacity: data.maxCapacity || 60,
    retailPrice: data.retailPrice || 0,
    cohortLength: data.cohortLength || "4 weeks",
  };
}

export async function fetchAvailableInventoryForPartner(
  partnerID,
  catalogueID,
) {
  const resp = await window.auth.client
    .get(routes.inventory.available(partnerID, catalogueID))
    .json<TInventory>();
  return formatForClient(resp);
}

export async function adminFetchInventoryByCatalogueID(catalogueID) {
  const url = routes.inventory.inventoryByCatalogueID(catalogueID);
  const resp = await window.auth.client.get(url).json<TInventory[]>();
  return resp;
}

export async function fetchInventory(params, cursor) {
  const url = routes.inventory.search();
  const resp = await window.auth.client
    .post(url, {
      json: {
        endDate: params.endDate,
        startDate: params.startDate,
        cursor,
      },
    })
    .json();
  return resp;
}

export async function fetchInventoryItem(id) {
  const resp = await window.auth.client
    .get(routes.inventory.show(id))
    .json<TInventory>();
  return formatForClient(resp);
}

export async function saveInventoryItem(data) {
  const { method, url } = requestOptions("inventory", data._id);
  const resp = await window.auth.client[method](url, {
    json: formatForServer(data),
  }).json<TInventory>();

  return formatForClient(resp);
}

export async function promoteToMarket(data) {
  const url = routes.inventory.market();
  const resp = await window.auth.client
    .patch(url, {
      json: formatForServer(data),
    })
    .json<TInventory>();
  return formatForClient(resp);
}

export async function migrateToCanvas(data) {
  const url = routes.inventory.migrate();
  const resp = await window.auth.client
    .post(url, {
      json: formatForServer(data),
    })
    .json<TInventory>();
  return formatForClient(resp);
}

export async function fetchCourseJob({ queryKey }) {
  const id = queryKey[1];
  const url = routes.inventory.fetchJob(id);
  const resp = await window.auth.client.get(url).json<TInventory>();
  return formatForClient(resp);
}

export async function deleteInventoryItem(_id: string, newInventoryItem) {
  const data: {
    newInventoryItem?: unknown;
  } = {};
  if (newInventoryItem) {
    data.newInventoryItem = newInventoryItem;
  }

  const url = routes.inventory.delete(_id);
  const resp = await window.auth.client
    .delete(url, {
      json: data,
    })
    .json();
  return resp;
}

export const useInventoryItem = (
  inventoryId,
): UseQueryResult<TInventory, Error> => {
  return useQuery({
    queryKey: ["inventory", inventoryId],
    queryFn() {
      return fetchInventoryItem(inventoryId);
    },
  });
};

const inventory = {
  propertiesOrDefaults,
  fetchInventory,
  fetchInventoryItem,
  fetchAvailableInventoryForPartner,
  adminFetchInventoryByCatalogueID,
  saveInventoryItem,
  deleteInventoryItem,
};

export default inventory;
