import confetti from "canvas-confetti";
import { motion } from "framer-motion";
import { MagnifyingGlass } from "phosphor-react";
import { useEffect, useRef } from "react";

import Stack from "@mui/material/Stack";

const EmptyIndexMessage = function EmptyIndexMessage({
  celebrate = false,
  icon,
  text,
  ...rest
}) {
  const message = useRef(null);
  const _icon = icon ? icon : <MagnifyingGlass size={100} weight="thin" />;

  useEffect(
    function () {
      if (!celebrate || !message.current) {
        return;
      }

      const rect = message.current.getBoundingClientRect();
      const l = rect.left + rect.width / 2;
      setTimeout(() => {
        confetti({
          particleCount: 120,
          spread: 90,
          origin: {
            x: l / window.innerWidth,
          },
        });
      }, 300);
    },
    [celebrate],
  );

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
      {...rest}
    >
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        ref={message}
        sx={{
          margin: "0 auto",
          fontSize: { xs: "1.5rem", md: "2rem" },
          fontWeight: 500,
          textAlign: "center",
          opacity: "0.6",
          letterSpacing: "-0.035rem",
        }}
      >
        <div>
          {celebrate ? (
            <motion.div
              initial={{ y: "0px" }}
              animate={{
                y: ["0px", "0px", "-5px", "0px"],
                scale: [1, 1, 1.05, 1],
              }}
              transition={{
                duration: 0.75,
              }}
            >
              {_icon}
            </motion.div>
          ) : (
            _icon
          )}
        </div>
        {celebrate ? (
          <motion.div
            initial={{ y: "0px" }}
            animate={{
              y: ["0px", "10px", "-45px", "0px"],
              scale: ["1,1", "1,1", "1.1,1", "1,1"],
            }}
            transition={{
              duration: 0.6,
            }}
          >
            {text}
          </motion.div>
        ) : (
          <div>{text}</div>
        )}
      </Stack>
    </div>
  );
};

export default EmptyIndexMessage;
