import FormErrorMessage from "@/components/FormErrorMessage";

const HookFormErrorMessage = function HookFormErrorMessage({
  errors,
  gutterBottom,
  name,
}) {
  return (
    <FormErrorMessage
      error={errors && errors[name] ? errors[name] : null}
      gutterBottom={gutterBottom || false}
    />
  );
};

export default HookFormErrorMessage;
