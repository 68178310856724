import { Controller } from "react-hook-form";

import { FormControl, RadioGroup, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

import { dollarsToFixed } from "@/lib/helpers";

export default function SetAmountOff({ setValue, control, watch }) {
  const amountType = watch("amountType");
  const amount = watch("amount");

  const percentSelected = function () {
    setValue("amount", Math.abs(parseFloat(amount)));
    setValue("amountType", "percentage");
  };

  const currencySelected = function () {
    setValue("amount", dollarsToFixed(amount));
    setValue("amountType", "currency");
  };

  const setPercentage = function (e) {
    setValue("amount", e.target.value);
    setValue("amountType", "percentage");
  };

  return (
    <div>
      <Typography gutterBottom variant="body1" fontWeight="500">
        Amount
      </Typography>

      <FormControl>
        <RadioGroup name="amountType" row defaultValue={amountType}>
          <FormControlLabel
            control={<Radio />}
            label="$"
            onChange={currencySelected}
            sx={{ mr: 4 }}
            value="currency"
          />
          <FormControlLabel
            control={<Radio />}
            label="%"
            onChange={percentSelected}
            value="percentage"
          />
        </RadioGroup>
      </FormControl>

      <FormControl margin="normal">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Controller
            control={control}
            name="amount"
            render={({ field: { ref, ...rest } }) => {
              return (
                <TextField
                  inputRef={ref}
                  label="Amount"
                  required
                  min="1.00"
                  size="small"
                  type="string"
                  value={
                    amountType === "currency"
                      ? amount
                      : Math.abs(parseFloat(amount))
                  }
                  variant="outlined"
                  {...rest}
                />
              );
            }}
            rules={{ required: "This field is required" }}
          />
        </div>
      </FormControl>

      {amountType === "percentage" && (
        <Box sx={{ pt: 0.5 }}>
          <FormControl>
            <ButtonGroup size="small" variant="outlined">
              <Button onClick={setPercentage} value={15}>
                15%
              </Button>
              <Button onClick={setPercentage} value={50}>
                50%
              </Button>
              <Button onClick={setPercentage} value={70}>
                70%
              </Button>
            </ButtonGroup>
          </FormControl>
        </Box>
      )}
    </div>
  );
}
