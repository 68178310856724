import Spinner from "@/components/Spinner";

import EmptyBox from "./EmptyBox";

function LoadingSelect({ children, sx = {} }) {
  return (
    <EmptyBox sx={sx}>
      <Spinner loading={true} sx={{ mr: 1 }} /> {children}
    </EmptyBox>
  );
}
export default LoadingSelect;
