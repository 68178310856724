import { useQuery } from "@tanstack/react-query";

import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";

import { formatDate } from "@/lib/helpers";

const GeneralTimeline = function ({ email, partnerID, fetchTimeline }) {
  const TIMELINE_QUERY_KEY = ["timeline", email, partnerID];

  // Data Fetching -------------------------------------------------------------

  const timeline = useQuery({
    queryKey: TIMELINE_QUERY_KEY,
    queryFn: fetchTimeline,
  });

  if (timeline.isLoading) {
    return <em>Loading</em>;
  }
  if (timeline.isError) {
    return <em>Error</em>;
  }
  if (!timeline.data) {
    return <em>No timeline information</em>;
  }

  const tl = timeline.data ?? [];

  return (
    <Timeline>
      {tl.map((h, index) => {
        return (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              color="text.secondary"
              style={{ flexBasis: "25%" }}
            >
              {formatDate(h.time)}
              <br />
              <span style={{ fontSize: "0.9rem" }}>
                {formatDate(h.time, "pp")}
              </span>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              {index !== tl.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent style={{ flexBasis: "70%" }}>
              {h.message}
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default GeneralTimeline;
