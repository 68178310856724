import routes from "@/lib/server-routes";

export default function requestOptions(
  model,
  ...urlParams
): {
  url: string;
  method: "patch" | "post";
} {
  const params = urlParams.filter(Boolean);
  return params.length
    ? { url: routes[model].patch.apply(null, params), method: "patch" }
    : { url: routes[model].post.apply(null, params), method: "post" };
}
