import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useDrawer } from "@/components/Drawer";
import ProfileDrawer from "@/components/Drawers/ProfileDrawer";
import clientRoutes from "@/lib/client-routes";

export default function Relationship({ profileData }) {
  const profileDrawer = useDrawer(ProfileDrawer);

  return (
    <>
      {profileData?.relatedProfiles?.length ? (
        <div>
          {profileData.relatedProfiles.map((profile, i) => {
            return (
              <div key={i} style={{ marginBottom: "2rem" }}>
                <Typography display="block" variant="overline">
                  {`${
                    profile.type.charAt(0).toUpperCase() + profile.type.slice(1)
                  }`}
                </Typography>
                <Typography display="block" variant="h6">
                  {profile.firstName ? profile.firstName : null}{" "}
                  {profile.lastName ? profile.lastName : null}
                </Typography>
                <Typography display="block" variant="body2">
                  <Link
                    onClick={() => {
                      profileDrawer.show({
                        email: profile.email,
                        partnerID: profile.partner._id,
                      });
                    }}
                    display="block"
                    noWrap
                    rel="noopener noreferrer"
                    sx={{ mr: 1 }}
                    target="_blank"
                    to={clientRoutes.profiles.index()}
                    underline="always"
                    variant="body1"
                  >
                    {profile.email}
                  </Link>
                </Typography>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <em>No relationships</em>
        </div>
      )}
    </>
  );
}
