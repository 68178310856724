import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useDrawer } from "@/components/Drawer";
import CouponNewDrawer from "@/components/Drawers/CouponFormDrawer";
import Layout from "@/components/Layout";
import TitleBar from "@/components/TitleBar";
import TitleBarButton from "@/components/TitleBarButton";
import { PS_COUPON_DEFAULTS } from "@/constants";
import useAlert from "@/hooks/use-alert";
import { fetchApplication } from "@/models/applications";
import { fetchProfile } from "@/models/profiles";
import { fetchScholarship, saveScholarship } from "@/models/scholarship";

import ShowView from "./components/ShowView";

const ScholarshipShow = function ScholarshipShow() {
  const queryClient = useQueryClient();
  const showAlert = useAlert();
  const couponFormDrawer = useDrawer(CouponNewDrawer);
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { author, partner } = useParams();

  // Data Fetching -------------------------------------------------------------

  const SCHOLARSHIP_QUERY_KEY = ["scholarships", author, partner];
  const PROFILE_QUERY_KEY = ["profiles", partner, author];

  const profile = useQuery({
    queryKey: PROFILE_QUERY_KEY,
    queryFn: fetchProfile,
  });
  const scholarship = useQuery({
    queryKey: SCHOLARSHIP_QUERY_KEY,
    queryFn: fetchScholarship,
    placeholderData: location.state?.scholarship ?? null,
  });
  const application = useQuery({
    queryKey: ["applications", author, partner],
    queryFn: fetchApplication,
  });

  const updateMutation = useMutation({
    mutationFn: saveScholarship,
    onSuccess(savedData) {
      showAlert({
        message: `Scholarship updated`,
      });
      queryClient.invalidateQueries({
        queryKey: ["scholarships"],
      });
      if (savedData.status === "approved") {
        couponFormDrawer.show({
          cancel() {
            couponFormDrawer.remove();
          },
          data: {
            ...PS_COUPON_DEFAULTS,
            partner: {
              _id: scholarship.data.partner._id,
              name: scholarship.data.partner.name,
            },
            scopedUsers: [profile.data.email],
          },
          errorCallback(error) {
            if (error.message.includes("409")) {
              showAlert({
                message: `Error! This coupon code already exists!`,
                severity: "error",
                duration: 10000,
              });
            } else {
              showAlert({
                message: `Error! An error has ocurred`,
                severity: "error",
                duration: 10000,
              });
            }
          },
          successCallback(data) {
            showAlert({
              message: `Successfully created coupon with code: ${data.code}`,
              duration: null,
            });
            couponFormDrawer.remove();
          },
        });
      }
    },
  });

  // Actions -------------------------------------------------------------

  const showCoupon = function () {
    couponFormDrawer.show({
      cancel() {
        couponFormDrawer.remove();
      },
      data: {
        ...PS_COUPON_DEFAULTS,
        partner: {
          _id: scholarship.data.partner._id,
          name: scholarship.data.partner.name,
        },
        scopedUsers: [profile.data.email],
      },
      errorCallback(error) {
        if (error.message.includes("409")) {
          showAlert({
            message: `Error! This coupon code already exists!`,
            horizontal: "center",
            severity: "error",
            duration: 10000,
          });
        } else {
          showAlert({
            message: `An error has ocurred!`,
            horizontal: "center",
            severity: "error",
            duration: 10000,
          });
        }
      },
      successCallback(data) {
        showAlert({
          message: `Successfully created coupon with code: ${data.code}`,
          horizontal: "center",
          duration: null,
        });
        couponFormDrawer.remove();
      },
    });
  };

  return (
    <Layout>
      <>
        <TitleBar
          backRoute={matches ? null : ".."}
          title={scholarship.data ? scholarship.data.author : "Scholarship"}
        >
          <TitleBarButton onClick={showCoupon} icon="add">
            New Coupon
          </TitleBarButton>
        </TitleBar>

        <Layout.Content>
          <ShowView
            application={application}
            isSaving={updateMutation.isPending}
            profile={profile}
            saveScholarship={(newData) => {
              updateMutation.mutate(newData);
            }}
            scholarship={scholarship}
          />
        </Layout.Content>
      </>
    </Layout>
  );
};

export default ScholarshipShow;
