import { useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

const EmailInputField = ({ setValue, fieldId, fieldName, watch }) => {
  const emails = watch("scopedUsers");
  const [emailInput, setEmailInput] = useState("");
  const [error, setError] = useState(null);

  const handleKeyDown = (event) => {
    if (["Enter", "Tab", ",", ";", " "].includes(event.key)) {
      event.preventDefault();

      const tempValue = emailInput.trim();

      if (isValid(tempValue)) {
        setValue("scopedUsers", [...emails, tempValue]);
        setEmailInput("");
      }
    }
  };

  const handleChange = (event, value, reason) => {
    if (reason === "removeOption") {
      setValue("scopedUsers", value);
    }
    setEmailInput(event.target.value);
    setError(null);
  };

  const handlePaste = (event) => {
    event.preventDefault();

    const paste = event.clipboardData.getData("text");
    const addresses = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);
    const toBeAdded = addresses.filter((address) => !isInList(address));
    setValue("scopedUsers", [...emails, ...toBeAdded]);
    setEmailInput("");
  };

  const isValid = (address) => {
    let error = null;

    if (isInList(address)) {
      error = `${address} has already been added.`;
    }

    if (!isEmail(address)) {
      error = `${address} is not a valid email address.`;
    }

    if (error) {
      setError(error);

      return false;
    }

    return true;
  };

  const isInList = (address) => {
    return emails.includes(address);
  };

  const isEmail = (address) => {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(address);
  };

  return (
    <>
      <Autocomplete
        multiple
        id={fieldId}
        name={fieldName}
        options={emails}
        defaultValue={emails}
        onChange={handleChange}
        style={{ width: "100%" }}
        freeSolo
        renderTags={(emails, getTagProps) =>
          emails.map((option, index) => (
            <Chip
              value={option}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth={true}
            size="small"
            value={emails}
            label="Email address(s)"
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            onChange={handleChange}
          />
        )}
      />
      {error && <p className="error">{error}</p>}
    </>
  );
};

export default EmailInputField;
