import { useInfiniteQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import MainDetail from "@/components/MainDetail";
import { STATUS_MAP } from "@/constants";
import clientRoutes from "@/lib/client-routes";
import { fetchScholarshipsByStatus } from "@/models/scholarship";

import Empty from "./components/Empty";
import SideBar from "./components/Sidebar/Sidebar";
import ScholarshipShow from "./show";

const ScholarshipsRoute = function () {
  const { status } = useParams();
  const { ...scholarships } = useInfiniteQuery({
    queryKey: ["scholarships", status],
    queryFn(context) {
      const cursor = context.pageParam;
      return fetchScholarshipsByStatus(status, cursor);
    },
    getNextPageParam: (lastPage) => lastPage.cursor,
  });

  return (
    <MainDetail
      MainComponent={SideBar}
      mainProps={{
        scholarships,
        supTitle: STATUS_MAP[status],
      }}
      DetailComponent={ScholarshipShow}
      detailPath={clientRoutes.scholarships.showPath}
      EmptyComponent={() => <Empty scholarships={scholarships} />}
    />
  );
};

export default ScholarshipsRoute;
