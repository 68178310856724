import * as PropTypes from "prop-types";

import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import IconButton from "@mui/material/IconButton";

import CopyToClipboardController from "./CopyToClipboardController";

const CopyToClipboardButton = function CopyToClipboardButton({
  className,
  style,
  text,
}) {
  return (
    <CopyToClipboardController text={text}>
      {(copied) => (
        <IconButton
          className={className}
          size="small"
          style={style}
          aria-label="copy to clipboard"
        >
          {copied && <AssignmentTurnedInIcon />}
          {!copied && <AssignmentIcon />}
        </IconButton>
      )}
    </CopyToClipboardController>
  );
};

CopyToClipboardButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string.isRequired,
};

export default CopyToClipboardButton;
