import * as PropTypes from "prop-types";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const PartnerSelect = function ({
  partners,
  allPartners = false,
  value = "0",
  ...rest
}) {
  return (
    <div>
      <InputLabel id="partner">Partner</InputLabel>

      <Select
        label="Partner"
        defaultValue={value || "0"}
        name="partner_id"
        size="small"
        value={value || "0"}
        sx={{ width: "100%" }}
        {...rest}
      >
        {allPartners ? (
          <MenuItem key="allPartners" value="0">
            All Partners
          </MenuItem>
        ) : (
          <MenuItem key="choosePartner" value="0">
            Choose Partner...
          </MenuItem>
        )}
        {partners.map((partner) => {
          return (
            <MenuItem key={partner._id} value={partner._id}>
              {partner.name}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

PartnerSelect.propTypes = {
  partners: PropTypes.array.isRequired,
  sx: PropTypes.object,
  value: PropTypes.string,
};

export default PartnerSelect;
