import routes from "@/lib/server-routes";

function formatForServer(data) {
  return {
    ...data,
  };
}

function formatForClient(data) {
  return {
    ...data,
  };
}

function propertiesOrDefaults(data) {
  return {
    ...data,
  };
}

export const fetchCart = async function ({ queryKey }) {
  const partnerId = queryKey[1];
  const email = queryKey[2];
  const url = routes.carts.cart(partnerId, email);
  const resp = await window.auth.client.get(url).json();
  return formatForClient(resp);
};

export const addLineItem = async function (partnerId, owner, inventoryItemID) {
  const url = routes.carts.addLineItem(partnerId);
  const resp = await window.auth.client.post(url, {
    json: {
      _id: inventoryItemID,
      owner,
      partner: {
        _id: partnerId,
      },
    },
  });
  return formatForClient(resp);
};

export const removeLineItem = async function (partnerId, owner, itemID) {
  const url = routes.carts.removeLineItem(partnerId, itemID);
  const resp = await window.auth.client.delete(url, {
    json: {
      owner,
    },
  });
  return formatForClient(resp);
};

export const applyCoupon = async function (partnerId, owner, code) {
  const url = routes.carts.applyCoupon(partnerId);
  const resp = await window.auth.client.post(url, {
    json: {
      code,
      owner,
    },
  });
  return formatForClient(resp);
};

export const removeCoupon = async function (partnerId, owner, code) {
  const url = routes.carts.removeCoupon(partnerId, code);
  const resp = await window.auth.client.delete(url, {
    json: {
      owner,
    },
  });
  return formatForClient(resp);
};

const cart = {
  addLineItem,
  applyCoupon,
  fetchCart,
  formatForServer,
  propertiesOrDefaults,
  removeCoupon,
};

export default cart;
