import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import Catalogue from "@/models/catalogue";
import { adminFetchInventoryByCatalogueID } from "@/models/inventory";

export default function Switch({ cancel, isSaving, inventoryItem, onSubmit }) {
  const [showConfirm, setShowConfirm] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      catalogue_item_id: inventoryItem.catalogueItem._id,
      inventory_id: "",
    },
  });

  const selectedCatalogueID = useWatch({
    control,
    name: "catalogue_item_id",
  });

  const catalogueItems = useQuery({
    queryKey: ["catalogue", inventoryItem.partner._id],
    queryFn() {
      return Catalogue.findAllByPartnerID(inventoryItem.partner._id);
    },
    enabled: Boolean(inventoryItem.partner._id),
  });

  const inventoryItems = useQuery({
    queryKey: ["available-inventory", selectedCatalogueID],
    queryFn() {
      return adminFetchInventoryByCatalogueID(selectedCatalogueID);
    },
    enabled: Boolean(selectedCatalogueID),
  });

  return (
    <>
      <div>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ mr: 1 }}>
            <InfoIcon color="info" />
          </Box>
          <Typography variant="body2" marginBottom={true}>
            This inventory item has active enrollments and must be reassigned
            before cancellation.
          </Typography>
        </Box>

        <br />

        <Typography variant="overline">
          <strong>Current</strong>
        </Typography>
        <Typography variant="body1">
          {inventoryItem.catalogueItem.name}
        </Typography>
        <Typography variant="body1">{inventoryItem.name}</Typography>

        <br />

        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="36"
          fill="none"
          viewBox="0 0 16 36"
          style={{ display: 'block', margin: '1.3rem 0 0.8rem 0' }}
        >
          <path
            fill="#000"
            fillRule="evenodd"
            d="M15.071 29.343l-6.364 6.364a1 1 0 01-1.414 0L.929 29.343a1 1 0 111.414-1.414L7 32.586V0h2v32.586l4.657-4.657a1 1 0 011.414 1.414z"
            clipRule="evenodd"
          ></path>
        </svg> */}

        <Typography variant="overline">
          <strong>Changing to</strong>
        </Typography>
        <form
          onSubmit={handleSubmit((formValues) => {
            const newInventoryItem = inventoryItems.data.find((item) => {
              return item._id === formValues.inventory_id;
            });

            onSubmit({ newInventoryItem });
          })}
          noValidate
        >
          {catalogueItems.isLoading && (
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <CircularProgress size="1.75rem" sx={{ mr: 2 }} /> Loading
              Catalogue Items
            </Box>
          )}
          {catalogueItems.data && (
            <FormControl
              fullWidth
              error={errors.catalogue_item_id ? true : false}
              margin="normal"
            >
              <InputLabel id="catalogue-item-select-field-label">
                Catalogue Item
              </InputLabel>
              <Controller
                control={control}
                name="catalogue_item_id"
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="catalogue-item-select-field-label"
                    size="small"
                  >
                    {catalogueItems.data.map((item) => {
                      return (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
                rules={{ required: "This field is required" }}
              />
            </FormControl>
          )}

          {inventoryItems.isLoading && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <CircularProgress size="1.75rem" sx={{ mr: 2 }} /> Loading
              Inventory Items
            </div>
          )}
          {inventoryItems.data && (
            <>
              {!inventoryItems.data.length && (
                <Typography
                  sx={{
                    border: "1px dashed rgba(153,153,153, 0.6)",
                    marginTop: "0.5rem",
                    borderRadius: 1.5,
                    px: 2,
                    py: 1,
                    opacity: 0.8,
                    fontStyle: "italic",
                  }}
                >
                  No Inventory items available
                </Typography>
              )}
              {Boolean(inventoryItems.data.length) && (
                <FormControl
                  fullWidth
                  error={errors.inventory_id ? true : false}
                  margin="normal"
                >
                  <InputLabel id="inventory-select-field-label">
                    Inventory Item
                  </InputLabel>
                  <Controller
                    control={control}
                    name="inventory_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="inventory-select-field-label"
                        size="small"
                      >
                        {inventoryItems.data
                          .filter((c) => {
                            return inventoryItem._id !== c._id;
                          })
                          .map((item) => {
                            return (
                              <MenuItem key={item._id} value={item._id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                    rules={{ required: "This field is required" }}
                  />
                  {errors.inventory_id && errors.inventory_id.message}
                </FormControl>
              )}
            </>
          )}

          <br />
          <br />
          <Button
            disabled={isSaving}
            onClick={cancel}
            variant="outlined"
            sx={{ mr: 1 }}
          >
            Never mind
          </Button>
          <Button
            disabled={isSaving}
            onClick={() => {
              setShowConfirm(true);
            }}
            sx={{ mr: 1 }}
            variant={showConfirm ? "outlined" : "contained"}
          >
            Next Step
          </Button>
          <br />
          {showConfirm && (
            <Box sx={{ pt: 1 }}>
              <Button disabled={isSaving} type="submit" variant="contained">
                Confirm Cancelation and Initiate Transfer
              </Button>
            </Box>
          )}
        </form>
      </div>
    </>
  );
}
