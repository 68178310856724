import requestOptions from "@/lib/request-options";
import sanitize from "@/lib/sanitize";
import routes from "@/lib/server-routes";

import type { TMentor, TMentorQualifiedCatalogueItem } from "./types";
import type { TInventory } from "@/models/inventory";

/* Constants
/* ------------------------------------------------------------------ */

export const YEARROUND = "year-round";
export const SEASONAL = "seasonal";
export const SUBSTITUTE = "substitute";
export const DORMANT = "dormant";

/* Helpers
/* ------------------------------------------------------------------ */

function formatForServer(data: TMentor): TMentor {
  return {
    ...sanitize<TMentor>(data),
  };
}

function formatForClient(data: TMentor): TMentor {
  return {
    ...data,
  };
}

function propertiesOrDefaults(data: Partial<TMentor>) {
  return {
    ...data,
  };
}

/* Finders
/* ------------------------------------------------------------------ */

const findByEmail = async function (email: string) {
  const url = routes.mentorSearch.show(email);
  const resp = await window.auth.client.get(url).json<TMentor>();
  return formatForClient(resp);
};

const findAll = async function () {
  const url = routes.mentorSearch.search();
  const resp = await window.auth.client.get(url).json<TMentor[]>();
  return resp.map(formatForClient);
};

const findAllByCatalogueItemID = async function (id: string) {
  const url = routes.mentorSearch.list(id);
  const resp = await window.auth.client.get(url).json<TMentor[]>();
  return resp.map(formatForClient);
};

const findAllByInventoryID = async function (id: string) {
  const url = routes.mentorAssignment.list(id);
  const resp = await window.auth.client.get(url).json<TMentor[]>();
  return resp.map(formatForClient);
};

/* Actions
/* ------------------------------------------------------------------ */

const save = async function (mentor: TMentor) {
  const { method, url } = requestOptions("mentors", mentor._id);

  const resp = await window.auth.client[method](url, {
    json: formatForServer(mentor),
  }).json<TMentor>();

  return formatForClient(resp);
};

const deleteMentor = async function (mentor: TMentor) {
  const url = routes.mentors.delete(mentor.email);
  const resp = await window.auth.client.delete(url);
  return resp.status;
};

const assign = async function ({ data, email }) {
  const url = routes.mentorAssignment.assign(email);

  const resp = await window.auth.client
    .post(url, {
      json: data,
    })
    .json<TMentorQualifiedCatalogueItem>();

  return resp;
};

const unassign = async function ({
  data,
  email,
}: {
  data: TInventory;
  email: string;
}) {
  const url = routes.mentorAssignment.unassign(email);

  const resp = await window.auth.client.delete(url, {
    json: data,
  });

  return resp.status > 199 && resp.status < 300;
};

const addQualifiedCatalogueItems = async function ({ data, email }) {
  const url = routes.qualifiedCatalogueItems.post(email);

  const resp = await window.auth.client
    .post(url, {
      json: data,
    })
    .json<TMentorQualifiedCatalogueItem>();

  return resp;
};

const deleteQualifiedCatalogueItems = async function (
  email: string,
  item: TMentorQualifiedCatalogueItem,
) {
  const url = routes.qualifiedCatalogueItems.delete(email);

  const resp = await window.auth.client.delete(url, {
    json: item,
  });

  return resp.status > 199 && resp.status < 300;
};

export default {
  addQualifiedCatalogueItems,
  assign,
  deleteMentor,
  deleteQualifiedCatalogueItems,
  findByEmail,
  findAll,
  findAllByCatalogueItemID,
  findAllByInventoryID,
  propertiesOrDefaults,
  save,
  unassign,
};
