import { useQuery } from "@tanstack/react-query";

import type { TMentor } from "./types";
import type { UseQueryResult } from "@tanstack/react-query";

import Mentor from "./index";

export function useMentorByEmail(
  email: string,
): UseQueryResult<TMentor, Error> {
  return useQuery({
    queryKey: ["mentor", email],
    queryFn() {
      return Mentor.findByEmail(email);
    },
    enabled: Boolean(email),
  });
}

export function useAllMentors(): UseQueryResult<TMentor[], Error> {
  return useQuery({
    queryKey: ["mentors"],
    queryFn() {
      return Mentor.findAll();
    },
  });
}

export function useMentorsByCatalogueItemID(
  id: string,
): UseQueryResult<TMentor[], Error> {
  return useQuery({
    queryKey: ["qualifiedMentors", id],
    queryFn() {
      return Mentor.findAllByCatalogueItemID(id);
    },
    enabled: Boolean(id),
  });
}

export function useMentorsAssignedToInventoryItem(
  id: string,
): UseQueryResult<TMentor[], Error> {
  return useQuery({
    queryKey: ["assignedMentors", id],
    queryFn() {
      return Mentor.findAllByInventoryID(id);
    },
    enabled: Boolean(id),
  });
}
