import { useQuery } from "@tanstack/react-query";
import { Student } from "phosphor-react";
import { useParams } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";

import SearchMessage from "@/components/SearchMessage";
import { fetchProfile } from "@/models/profiles";

import Results from "./Results";

const SearchResults = ({ showResults }) => {
  const params = useParams();
  const profiles = useQuery({
    queryKey: ["profile", "all", params.email],
    queryFn: fetchProfile,
  });

  if (!showResults) {
    return null;
  }

  if (profiles.isError) {
    return (
      <SearchMessage
        icon={Student}
        text={
          profiles.error.response.status === 404
            ? "Profile could not be found"
            : "An error has occurred"
        }
      />
    );
  }

  if (profiles.isLoading) {
    return <LinearProgress color="secondary" />;
  }

  return profiles?.data ? <Results profiles={profiles.data} /> : null;
};

export default SearchResults;
