import { useQuery } from "@tanstack/react-query";
import { Controller } from "react-hook-form";

import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import FormErrorMessage from "@/components/FormErrorMessage";
import {
  FS_COUPON_DEFAULTS,
  PROMO_COUPON_DEFAULTS,
  PS_COUPON_DEFAULTS,
} from "@/constants";
import { fetchApplication } from "@/models/applications";
import { useCatalogueForPartner } from "@/models/catalogue/hooks";

const SelectAdjustmentType = ({
  scopedUsers,
  partner,
  setValue,
  errors,
  control,
}) => {
  const application = useQuery({
    queryKey: ["applications", scopedUsers[0], partner._id],
    queryFn: fetchApplication,
  });
  const catalogueItems = useCatalogueForPartner(application.data?.partner?._id);

  return (
    <>
      <Typography gutterBottom variant="body1" fontWeight="500">
        Adjustment Type
      </Typography>
      <FormControl fullWidth error={errors.type ? true : false} margin="normal">
        <InputLabel id="adjustment-select-field-label">
          Adjustment Type
        </InputLabel>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Select
              {...field}
              labelId="adjustment-select-field-label"
              label="Adjustment Type"
              size="small"
            >
              <MenuItem
                value="promotional"
                onClick={() => {
                  setValue(
                    "hasCustomCode",
                    PROMO_COUPON_DEFAULTS.hasCustomCode,
                  );
                  setValue("amountType", PROMO_COUPON_DEFAULTS.amountType);
                  setValue("amount", PROMO_COUPON_DEFAULTS.amount);
                  setValue("maxUses", PROMO_COUPON_DEFAULTS.maxUses);
                  setValue("expiration", PROMO_COUPON_DEFAULTS.expiration);
                }}
              >
                Promotional
              </MenuItem>
              <MenuItem
                value="fullScholarship"
                onClick={() => {
                  setValue("hasCustomCode", FS_COUPON_DEFAULTS.hasCustomCode);
                  setValue("amountType", FS_COUPON_DEFAULTS.amountType);
                  setValue("amount", FS_COUPON_DEFAULTS.amount);
                  setValue("maxUses", FS_COUPON_DEFAULTS.maxUses);
                  setValue("expiration", FS_COUPON_DEFAULTS.expiration);
                  setValue(
                    "partner",
                    partner || application.data.partner || null,
                  );
                  setValue("scopedItems", catalogueItems.data || []);
                }}
              >
                Full Scholarship
              </MenuItem>
              <MenuItem
                value="partialScholarship"
                onClick={() => {
                  setValue("hasCustomCode", PS_COUPON_DEFAULTS.hasCustomCode);
                  setValue("amountType", PS_COUPON_DEFAULTS.amountType);
                  setValue("amount", PS_COUPON_DEFAULTS.amount);
                  setValue("maxUses", PS_COUPON_DEFAULTS.maxUses);
                  setValue("expiration", PS_COUPON_DEFAULTS.expiration);
                  setValue(
                    "partner",
                    partner || application.data.partner || null,
                  );
                }}
              >
                Partial Scholarship
              </MenuItem>
            </Select>
          )}
          rules={{ required: "This field is required" }}
        />
        <FormErrorMessage error={errors["type"]} />
      </FormControl>
    </>
  );
};

export default SelectAdjustmentType;
