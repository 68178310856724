export const TWO_COLUMN_STYLE = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridGap: "2rem",
  listStyle: "none",
  margin: 0,
  padding: 0,
};

export const THREE_COLUMN_STYLE = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gridGap: "2rem",
  listStyle: "none",
  margin: 0,
  padding: 0,
};
