import { Link } from "react-router-dom";

import TimelineContent from "@mui/lab/TimelineContent";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import { REFUND_REASONS_MAP } from "@/constants";
import clientRoutes from "@/lib/client-routes";
import { centsToPrice } from "@/lib/helpers";
import { useInventoryItem } from "@/models/inventory";

const ListItemSX = {
  borderRadius: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  cursor: "pointer",
  fontSize: "90%",
};

export const TimelineEnrollmentEvent = ({ timelineEvent }) => {
  const inventoryData = useInventoryItem(timelineEvent.eventData.inventory._id);
  const linkText = (
    <div>
      <u>{inventoryData.data?.catalogueItem.name}</u>
      <br />
      <u>{inventoryData.data?.name}</u>
    </div>
  );

  return (
    <List>
      <ListItemButton
        component={Link}
        to={clientRoutes.inventorySearch.show(inventoryData.data?._id)}
        sx={ListItemSX}
      >
        <ListItemText
          primaryTypographyProps={{ color: "primary" }}
          primary={linkText}
        />
      </ListItemButton>
    </List>
  );
};
const FormattedPrevInventoryData = (id) => {
  const prevData = useInventoryItem(id);
  return (
    <div>
      <u>{prevData.data?.catalogueItem?.name}</u>
      <br />
      <u>{prevData.data?.name}</u>
    </div>
  );
};
const FormattedNextInventoryData = (id) => {
  const nextData = useInventoryItem(id);
  return (
    <div>
      <u>{nextData.data?.catalogueItem?.name}</u>
      <br />
      <u>{nextData.data?.name}</u>
    </div>
  );
};
export const TimelineSwitchEvent = ({ timelineEvent }) => {
  const prevlinkText = (
    <div>
      {timelineEvent.eventData?.prevInventoryItem?.catalogueItem?.name ? (
        <div>
          <u>{timelineEvent.eventData?.prevInventoryItem.catalogueItem.name}</u>
          <br />
          <u>{timelineEvent.eventData?.prevInventoryItem.name}</u>
        </div>
      ) : (
        <FormattedPrevInventoryData
          id={timelineEvent.eventData?.prevInventoryItem._id}
        />
      )}
    </div>
  );
  const nextlinkText = (
    <div>
      {timelineEvent.eventData?.nextInventoryItem?.catalogueItem?.name ? (
        <div>
          <u>{timelineEvent.eventData?.nextInventoryItem.catalogueItem.name}</u>
          <br />
          <u>{timelineEvent.eventData?.nextInventoryItem.name}</u>
        </div>
      ) : (
        <FormattedNextInventoryData
          id={timelineEvent.eventData?.nextInventoryItem._id}
        />
      )}
    </div>
  );
  return (
    <div>
      <List>
        <ListItemButton
          component={Link}
          to={clientRoutes.inventorySearch.show(
            timelineEvent.eventData?.prevInventoryItem._id,
          )}
          sx={ListItemSX}
        >
          <ListItemText
            primaryTypographyProps={{ color: "primary" }}
            primary={prevlinkText}
          />
        </ListItemButton>
      </List>
      <Typography sx={{ paddingLeft: 4 }}>To</Typography>
      <List>
        <ListItemButton
          component={Link}
          to={clientRoutes.inventorySearch.show(
            timelineEvent.eventData?.nextInventoryItem._id,
          )}
          sx={ListItemSX}
        >
          <ListItemText
            primaryTypographyProps={{ color: "primary" }}
            primary={nextlinkText}
          />
        </ListItemButton>
      </List>
    </div>
  );
};
export const TimelineRefundEvent = ({ timelineEvent }) => {
  return (
    <TimelineContent style={{ flexBasis: "70%" }}>
      {`Amount: $${centsToPrice(timelineEvent.eventData.refund.amount)}`}
      <br />
      {`Reason: ${REFUND_REASONS_MAP[timelineEvent.eventData.refund.reason]}`}
      <br />
      Attributed Line Items:
      <List>
        {timelineEvent.eventData.refund.scopedItems.map((item) => {
          const linkText = (
            <div>
              <u>{item.lineItem.catalogueItem.name}</u>
              <br />
              <u>{item.lineItem.name}</u>
            </div>
          );
          return (
            <ListItemButton
              key={item.lineItem._id}
              component={Link}
              to={clientRoutes.inventorySearch.show(item.lineItem._id)}
              sx={ListItemSX}
            >
              <Box>
                <ListItemText
                  primaryTypographyProps={{ color: "primary" }}
                  primary={linkText}
                />
                {`Amount: $${centsToPrice(item.amount)}`}
              </Box>
            </ListItemButton>
          );
        })}
      </List>
    </TimelineContent>
  );
};
