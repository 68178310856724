import { CurrencyDollar } from "phosphor-react";

import EmptyIndexMessage from "@/components/EmptyIndexMessage";

const Empty = function ({ scholarships }) {
  const isEmpty =
    !scholarships.isLoading &&
    !scholarships.isFetching &&
    Boolean(scholarships.data) &&
    !scholarships.data.pages.length;

  const text = (function () {
    if (scholarships.isLoading || scholarships.isFetching) {
      return "Inbox Loading";
    }
    if (isEmpty) {
      return "Inbox Empty!";
    }
    if (Boolean(scholarships.data?.pages) && scholarships.data?.pages.length) {
      return "Select a scholarship";
    }

    return "Inbox";
  })();

  return (
    <EmptyIndexMessage
      celebrate={isEmpty}
      icon={<CurrencyDollar size={100} weight="thin" />}
      text={text}
    />
  );
};

export default Empty;
