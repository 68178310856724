import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";

import CatalogueItemSelectMultiple from "@/components/CatalogueItemSelectMultiple";
import EmptyBox from "@/components/EmptyBoxField/EmptyBox";
import LoadingSelect from "@/components/EmptyBoxField/LoadingSelect";
import MultiSelect from "@/components/MultiSelect/MultiSelect";
import Catalogue from "@/models/catalogue";
import { fetchPartners } from "@/models/partner";

export default function SearchFormCatalogue({ onSubmit }) {
  const [data, setData] = useState({
    partner_ids: [],
    catalogueItem_ids: [],
  });

  const partners = useQuery({
    queryKey: ["partners"],
    queryFn: fetchPartners,
  });

  const catalogueItems = useQuery({
    queryKey: ["catalogue", data.partner_ids],
    queryFn() {
      return Promise.all(
        data.partner_ids.map((id) => Catalogue.findAllByPartnerID(id)),
      );
    },
    enabled: Boolean(data.partner_ids),
  });

  const onChange = function (e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = function (e) {
    e.preventDefault();
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          p: 2,
        }}
      >
        {partners.isLoading ? (
          <LoadingSelect sx={{ width: "40%", flexGrow: 1 }}>
            Loading partners
          </LoadingSelect>
        ) : null}
        {partners.data && (
          <FormControl sx={{ width: "40%", flexGrow: 1 }}>
            <MultiSelect
              input={partners.data}
              value={data.partner_ids}
              onChange={onChange}
              label={"Partner(s)"}
              name={"partner_ids"}
            />
          </FormControl>
        )}
        <Box
          sx={{
            display: "flex",
            width: "60%",
            flexGrow: 1,
          }}
        >
          {catalogueItems.isLoading ? (
            <LoadingSelect>Loading catalogue</LoadingSelect>
          ) : catalogueItems.data ? (
            <FormControl fullWidth>
              <CatalogueItemSelectMultiple
                items={catalogueItems.data}
                onChange={(catalogueItem_ids) => {
                  setData({
                    ...data,
                    catalogueItem_ids,
                  });
                }}
                value={data.catalogueItem_ids}
              />
            </FormControl>
          ) : !catalogueItems.data ? (
            <EmptyBox />
          ) : null}
        </Box>

        <Button
          color="neutral"
          type="submit"
          variant="outlined"
          sx={{ flexShrink: 0 }}
        >
          Search
        </Button>
      </Box>
    </form>
  );
}
