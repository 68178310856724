import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { TWO_COLUMN_STYLE } from "../../style";
import Heading from "../Heading";

const AcademicDetails = ({ school }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const TWO_COL = matches
    ? TWO_COLUMN_STYLE
    : {
        ...TWO_COLUMN_STYLE,
        display: "block",
      };

  return (
    <>
      <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
        Academic Information
      </Typography>
      <ul style={TWO_COL}>
        <li style={{ margin: 0 }} key="highSchoolGradYear">
          <Heading>High School Graduation Year</Heading>
          {school.gradYear}
        </li>
        <li style={{ margin: 0 }} key="currentSchoolName">
          <Heading>Current School Name</Heading>
          {school.name}
        </li>
      </ul>

      <br />
      <br />

      <Typography variant="h6" sx={{ mb: 2 }}>
        School Address
      </Typography>
      <ul style={TWO_COL}>
        <li style={{ gridColumn: "span 2" }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow key="studentCountry">
                  <TableCell size="small" sx={{ width: "50%", opacity: 0.7 }}>
                    School Country:
                  </TableCell>
                  <TableCell size="small">{school.address.country}</TableCell>
                </TableRow>
                {school.address.region && (
                  <TableRow key="schoolInternationalRegion">
                    <TableCell size="small" sx={{ opacity: 0.7 }}>
                      School International Region:
                    </TableCell>
                    <TableCell size="small">{school.address.region}</TableCell>
                  </TableRow>
                )}
                <TableRow key="schoolState">
                  <TableCell size="small" sx={{ opacity: 0.7 }}>
                    School State:
                  </TableCell>
                  <TableCell size="small">{school.address.state}</TableCell>
                </TableRow>
                <TableRow key="schoolCity">
                  <TableCell size="small" sx={{ opacity: 0.7 }}>
                    School City:
                  </TableCell>
                  <TableCell size="small">{school.address.city}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </li>
      </ul>
      <br />
    </>
  );
};

export default AcademicDetails;
