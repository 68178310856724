import * as yup from "yup";

import createDate from "@/lib/create-date";
import { centsToPrice, priceToCents } from "@/lib/helpers";
import requestOptions from "@/lib/request-options";
import routes from "@/lib/server-routes";

const schema = yup.object().shape({
  hasCustomCode: yup.boolean(),
  code: yup.string().when("hasCustomCode", {
    is: true,
    then: yup.string().required("The Custom Code is required"),
  }),
  type: yup.string().required("The Adjustment Type is required"),
  amountType: yup.string(),
  amount: yup
    .number("The Amount must be a number")
    .required("The Amount is required")
    .positive("The Amount must be positive")
    .when("amountType", {
      is: "percentage",
      then() {
        return yup
          .number()
          .max(100, "The percentage off can not be greater than 100");
      },
    }),
  expiration: yup
    .date("The Expiration Date must be a valid date")
    .required("The Expiration Date is required")
    .min(createDate(), "The Expiration Date must be after today"),
  maxUses: yup
    .number("Max uses must be a number")
    .integer("Max uses must be an integer")
    .min(0, "Max Uses must be greater than or equal to 0"),
  scopedItems: yup.array().of(yup.object()),
  scopedUsers: yup.array().of(yup.string().email()),
});

function formatForServer(data) {
  return {
    ...data,
    amount:
      data.amountType === "currency" ? priceToCents(data.amount) : data.amount,
    maxUses: data.maxUses || 0,
  };
}

function formatForClient(data) {
  return {
    ...data,
    amount:
      data.amountType === "currency" ? centsToPrice(data.amount) : data.amount,
  };
}

function propertiesOrDefaults(data) {
  return {
    ...data,
    code: data.code || "",
    hasCustomCode: data.hasCustomCode ?? true,
    type: data.type || "promotional",
    amount: data.amount || "10",
    amountType: data.amountType || "percentage",
    expiration: data.expiration || createDate(0, 0, 1),
    maxUses: data.maxUses || "",
    partner: data.partner || {},
    scopedItems: data.scopedItems || [],
    scopedUsers: data.scopedUsers || [],
  };
}

export async function createCoupon(data) {
  const url = routes.coupons.post();
  const resp = await window.auth.client
    .post(url, {
      json: formatForServer(data),
    })
    .json();
  return formatForClient(resp);
}

export async function saveCoupon(data) {
  const { method, url } = requestOptions("coupons", data.code);
  const resp = await window.auth.client[method](url, {
    json: formatForServer(data),
  }).json();
  return formatForClient(resp);
}

export const fetchCoupon = async function ({ queryKey }) {
  const id = queryKey[1];
  const url = routes.coupons.show(id);
  const resp = await window.auth.client.get(url).json();
  return formatForClient(resp);
};

export const fetchCoupons = async function () {
  const url = routes.coupons.index();
  const resp = await window.auth.client.get(url).json();
  return resp.map(formatForClient);
};

export const deleteCoupon = async function (id) {
  const url = routes.coupons.delete(id);
  const resp = await window.auth.client.delete(url).json();
  return resp;
};

const coupon = {
  schema,
  propertiesOrDefaults,
  createCoupon,
  saveCoupon,
  fetchCoupon,
  fetchCoupons,
  deleteCoupon,
};

export default coupon;
