import { find, get } from "lodash-es";
import { useId } from "react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";

export default function MultiSelect({
  input,
  onChange,
  value = [],
  label,
  name,
}) {
  const id = useId();
  const getNameFromID = function (all, _id) {
    return get(find(all, ["_id", _id]), "name");
  };

  return (
    <div>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        sx={{ width: "100%" }}
        multiple
        label={label}
        name={name}
        labelId={id}
        size="small"
        value={value}
        onChange={onChange}
        input={<OutlinedInput label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((_id) => (
              <Chip size="small" key={_id} label={getNameFromID(input, _id)} />
            ))}
          </Box>
        )}
      >
        {input?.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
