import { forwardRef, useId } from "react";

import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import FormLabel from "@mui/material/FormLabel";

import type { ChangeEventHandler, ForwardedRef } from "react";

export const EditTextNotepad = forwardRef(function EditTextNotepad(
  {
    color = "yellow",
    disabled = false,
    onChange,
    readonly = false,
    title,
    value,
  }: {
    color?: "yellow" | "purple";
    disabled?: boolean;
    onChange: ChangeEventHandler<HTMLTextAreaElement>;
    readonly?: boolean;
    title: string;
    value: string;
  },
  ref: ForwardedRef<HTMLTextAreaElement>,
) {
  const id = useId();

  return (
    <div className={`notepad ${color}`}>
      <FormLabel className="label" htmlFor={id}>
        {title}
      </FormLabel>
      {readonly ? (
        <div className="textarea">{value}</div>
      ) : (
        <TextareaAutosize
          className="textarea"
          disabled={disabled}
          id={id}
          minRows={4}
          name="notes"
          onChange={onChange}
          value={value ?? ""}
          ref={ref}
        />
      )}
    </div>
  );
});
