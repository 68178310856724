import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Chip from "@/components/Chip";
import Data from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import ApplicationDrawer from "@/components/Drawers/ApplicationDrawer";
import ProfileDrawer from "@/components/Drawers/ProfileDrawer";
import GeneralTimeline from "@/components/Timeline";
import { FORM_PAPER_SX, STATUS_COLOR_MAP, STATUS_MAP } from "@/constants";
import useAlert from "@/hooks/use-alert";
import { formatDate } from "@/lib/helpers";
import { fetchTimeline } from "@/models/scholarship";

import ChangeStatus from "./ChangeStatus";
import getScore from "./getScore";
import ScholarshipRequest from "./ScholarshipRequest";

export default function ScholarshipShowView({
  isSaving,
  application,
  profile,
  scholarship,
  saveScholarship,
}) {
  const applicationDrawer = useDrawer(ApplicationDrawer);
  const profileDrawer = useDrawer(ProfileDrawer);
  const showAlert = useAlert();

  const showApplication = function () {
    applicationDrawer.show({
      profile,
      scholarship,
      successCallback() {
        showAlert({
          message: `Application updated`,
        });
        applicationDrawer.remove();
      },
    });
  };

  return (
    <>
      <Typography
        as="h2"
        display="flex"
        variant="h5"
        sx={{ alignItems: "center" }}
      >
        {profile.isLoading ? (
          <>
            <span style={{ opacity: 0.5 }}>Loading applicant</span>
            <IconButton style={{ opacity: 0 }} onClick={() => {}}>
              <VisibilityIcon />
            </IconButton>
          </>
        ) : profile.data ? (
          <>
            {`${profile.data.firstName} ${profile.data.lastName}`}
            <IconButton
              onClick={() => {
                profileDrawer.show({
                  email: profile.data.email,
                  partnerID: profile.data.partner._id,
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </>
        ) : null}
      </Typography>

      <Typography as="h2" display="block" marginBottom={true} variant="h5">
        {scholarship.data && scholarship.data.partner.name}
      </Typography>
      <br />

      <Data>
        <Data.Item>
          <Data.Label>Scholarship Status</Data.Label>
          <Data.Content>
            {scholarship.data ? (
              <Chip
                color={STATUS_COLOR_MAP[scholarship.data.status]}
                label={STATUS_MAP[scholarship.data.status]}
                size="small"
              />
            ) : (
              <em>none</em>
            )}
          </Data.Content>
        </Data.Item>
        <Data.Item>
          <Data.Label>Application Status</Data.Label>
          <Data.Content>
            {application.data && (
              <Chip
                color={STATUS_COLOR_MAP[application.data.status]}
                label={STATUS_MAP[application.data.status]}
                size="small"
              />
            )}
            {!application.data && <em>none</em>}
          </Data.Content>
          {application.data && (
            <IconButton onClick={showApplication} size="small">
              <VisibilityIcon />
            </IconButton>
          )}
        </Data.Item>
        <Data.Item>
          <Data.Label>Needs/Benefits Score:</Data.Label>
          <Data.Content>{`${getScore(
            scholarship.data,
          )} out of 10`}</Data.Content>
        </Data.Item>
        <Data.Item>
          <Data.Label>Created On:</Data.Label>
          <Data.Content>{formatDate(scholarship.data.createdAt)}</Data.Content>
        </Data.Item>
      </Data>

      <br />

      <Typography variant="h6" sx={{ mb: 2 }}>
        Scholarship Status
      </Typography>
      {scholarship.data && (
        <ChangeStatus
          isSaving={isSaving}
          save={(data) => {
            saveScholarship(data);
          }}
          scholarship={scholarship.data}
        />
      )}

      <br />

      <Typography variant="h6" sx={{ mb: 2 }}>
        Scholarship Request
      </Typography>
      {scholarship.data ? (
        <ScholarshipRequest
          scholarship={scholarship.data}
          essay={application?.data?.essay}
          isSaving={isSaving}
          save={(data) => {
            saveScholarship(data);
          }}
        />
      ) : null}

      <br />

      <Typography variant="h6" sx={{ mb: 1 }}>
        Timeline
      </Typography>
      {scholarship.isLoading ? <div>Loading...</div> : null}
      {scholarship.data ? (
        <Paper elevation={1} sx={FORM_PAPER_SX}>
          <GeneralTimeline
            email={scholarship.data.author}
            partnerID={scholarship.data.partner._id}
            fetchTimeline={fetchTimeline}
          />
        </Paper>
      ) : null}
    </>
  );
}
