import { useQuery } from "@tanstack/react-query";

import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import EditIcon from "@mui/icons-material/Edit";
import MoveUp from "@mui/icons-material/MoveUp";
import Publish from "@mui/icons-material/Publish";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import { isPublished } from "@/lib/helpers";
import { fetchCourseJob } from "@/models/inventory";

const ButtonStack = ({
  inventoryItem,
  handleCancel,
  setOpenDialog,
  handleEdit,
  courseSections,
}) => {
  const courseJob = useQuery({
    queryKey: ["courseJob", inventoryItem.data._id],
    queryFn: fetchCourseJob,
  });

  const CanvasButton = ({ disabled = null }) => (
    <Button
      color="secondary"
      onClick={() => setOpenDialog("migrate")}
      startIcon={<MoveUp />}
      variant="outlined"
      disabled={disabled}
    >
      Create in Canvas
    </Button>
  );
  return (
    <Stack sx={{ pt: 2 }} spacing={2} direction="row">
      <Button
        disabled={inventoryItem.data.status === "canceled"}
        color="secondary"
        onClick={() => {
          handleEdit(inventoryItem.data);
        }}
        startIcon={<EditIcon />}
        variant="outlined"
      >
        Edit
      </Button>
      <Button
        color="secondary"
        disabled={inventoryItem.data.status === "canceled"}
        onClick={handleCancel}
        startIcon={<DoDisturbIcon />}
        variant="outlined"
      >
        Cancel
      </Button>
      {inventoryItem.data.status === "preMarket" ? (
        <Button
          color="secondary"
          onClick={() => setOpenDialog("promote")}
          startIcon={<Publish />}
          variant="outlined"
        >
          Promote to Market
        </Button>
      ) : null}
      {isPublished(inventoryItem.data.status) ? (
        courseSections.isError && courseJob.isError ? (
          <CanvasButton />
        ) : (
          <Tooltip
            title={
              courseSections.isLoading || courseJob.isLoading
                ? "Fetching Canvas information, please wait..."
                : "Canvas course or job already created"
            }
          >
            <div>
              <CanvasButton disabled={true} />
            </div>
          </Tooltip>
        )
      ) : null}
      {courseJob.isSuccess &&
      courseJob.data?.migrationStatus !== "completed" ? (
        <span style={{ fontSize: ".8rem", alignSelf: "center" }}>
          Migration job is currently: {courseJob.data?.migrationStatus}
        </span>
      ) : null}
    </Stack>
  );
};

export default ButtonStack;
