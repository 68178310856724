import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";

import PaymentIcon from "@mui/icons-material/Payment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Chip from "@/components/Chip";
import Data from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import CouponDrawer from "@/components/Drawers/CouponDrawer";
import ProfileDrawer from "@/components/Drawers/ProfileDrawer";
import RefundDrawer from "@/components/Drawers/RefundDrawer";
import Layout from "@/components/Layout";
import useAlert from "@/hooks/use-alert";
import { fetchFulfillment } from "@/models/fulfillment";
import { fetchProfile } from "@/models/profiles";

import LineItems from "./LineItems";
import OrderTimeline from "./OrderTimeline";

const orderStatusColor = function (status) {
  switch (status) {
    case "fulfilled":
      return "green";
    default:
      return "grey";
  }
};

export default function OrderDetails({ orderData }) {
  const theme = useTheme();
  const { summary, student, partner, coupon } = orderData;
  const { firstName, lastName, email } = student;
  const profileDrawer = useDrawer(ProfileDrawer);
  const refundDrawer = useDrawer(RefundDrawer);
  const couponDrawer = useDrawer(CouponDrawer);
  const showAlert = useAlert();
  const params = useParams();

  const queryClient = useQueryClient();
  const PROFILE_QUERY_KEY = ["profiles", partner._id, email];
  const FULFILLMENT_QUERY_KEY = ["fulfillment", orderData._id];

  const profile = useQuery({
    queryKey: PROFILE_QUERY_KEY,
    queryFn: fetchProfile,
  });
  const fulfillment = useQuery({
    queryKey: FULFILLMENT_QUERY_KEY,
    queryFn: fetchFulfillment,
  });

  // Event Handlers -----------------------------------------------------------

  const showProfile = function () {
    profileDrawer.show({
      email: profile.data.email,
      partnerID: profile.data.partner._id,
    });
  };
  const openRefundDrawer = function () {
    refundDrawer.show({
      cancel() {
        refundDrawer.remove();
      },
      data: orderData,
      orderID: orderData._id,
      errorCallback() {
        showAlert({
          message: `An error has ocurred!`,
          severity: "error",
          duration: 10000,
        });
      },
      successCallback() {
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ["orders", params._id],
          });
        }, 1000);
        showAlert({
          message: "Successfully processed refund",
          duration: 4000,
        });
        refundDrawer.remove();
      },
    });
  };

  const openCouponDrawer = function () {
    couponDrawer.show({ couponID: coupon.code });
  };
  return (
    <Layout.Content>
      <Box width={"100%"}>
        <Typography
          as="h2"
          display="flex"
          variant="h5"
          sx={{ alignItems: "center" }}
        >
          {`${firstName} ${lastName}`}
          <IconButton onClick={showProfile}>
            <VisibilityIcon />
          </IconButton>
        </Typography>
        <Typography as="h2" variant="h5">
          {email}
        </Typography>
        <Typography as="h2" variant="h5">
          {orderData.partner.name}
        </Typography>

        <br />

        <Data>
          <Data.Item>
            <Data.Label>Order Status</Data.Label>
            <Data.Content>
              <Chip
                color={orderStatusColor(orderData.status)}
                label={orderData.status}
              />
            </Data.Content>
          </Data.Item>
          <Data.Item>
            <Data.Label>Retail Price:</Data.Label>
            <Data.Content>{summary.formattedRetailPrice}</Data.Content>
          </Data.Item>
          <Data.Item>
            <Data.Label>Total Discount:</Data.Label>
            <Data.Content>
              {summary.formattedDiscountAmount ?? "$0.00"}
              {coupon ? (
                <Link
                  as="button"
                  onClick={openCouponDrawer}
                  style={{ marginLeft: "1em" }}
                >
                  View Coupon
                </Link>
              ) : null}
            </Data.Content>
          </Data.Item>
          <Data.Item>
            <Data.Label>Total Refund Amount:</Data.Label>
            <Data.Content>
              {summary.formattedAmountRefunded
                ? summary.formattedAmountRefunded
                : "$0.00"}
            </Data.Content>
          </Data.Item>
          <Data.Item>
            <Data.Label>Adjusted Total:</Data.Label>
            <Data.Content>{summary.formattedPriceToPay}</Data.Content>
          </Data.Item>
          <Data.Item
            sx={{
              backgroundColor: theme.palette.grey[50],
              ...theme.applyStyles("dark", {
                backgroundColor: theme.palette.grey[900],
              }),
            }}
          >
            {orderData.status !== "fulfilled" ? (
              <Data.Label
                variant="body1"
                sx={{
                  flexGrow: 1,
                  flexShrink: 0,
                  fontWeight: 700,
                }}
              >
                Total to be paid:
              </Data.Label>
            ) : (
              <Data.Label
                variant="body1"
                sx={{
                  flexGrow: 1,
                  flexShrink: 0,
                  fontWeight: 700,
                }}
              >
                Total paid:
              </Data.Label>
            )}
            <Data.Content
              display="flex"
              variant="body1"
              sx={{ alignItems: "center", fontWeight: 700, width: "100%" }}
            >
              <span style={{ flexGrow: 1 }}>{summary.formattedPriceToPay}</span>
              <Stack direction="column" spacing={1} style={{ flexShrink: 0 }}>
                <Button
                  color="secondary"
                  disabled={orderData.status !== "fulfilled"}
                  onClick={openRefundDrawer}
                  startIcon={<PaymentIcon />}
                  variant="outlined"
                >
                  Refund Payment
                </Button>
              </Stack>
            </Data.Content>
          </Data.Item>
        </Data>
      </Box>
      <br />
      <Box>
        <Typography as="h2" display="block" marginBottom={true} variant="h6">
          Line Items:
        </Typography>
        <LineItems
          lineItems={orderData.lineItems}
          email={email}
          order={orderData}
        />
      </Box>
      <br />
      <br />
      {fulfillment.data?.events && (
        <OrderTimeline history={fulfillment.data?.events} />
      )}
    </Layout.Content>
  );
}
