import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isFuture } from "date-fns";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useDrawer } from "@/components/Drawer";
import CouponFormDrawer from "@/components/Drawers/CouponFormDrawer";
import Layout from "@/components/Layout";
import TitleBar from "@/components/TitleBar";
import TitleBarButton from "@/components/TitleBarButton";
import UPDialog from "@/components/UPDialog";
import useAlert from "@/hooks/use-alert";
import clientRoutes from "@/lib/client-routes";
import { deleteCoupon, fetchCoupon } from "@/models/coupon";

import CouponDetails from "./components/CouponDetails";

function CouponShow({ couponID = null }) {
  const params = useParams();
  const _id = couponID || params._id;
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const showAlert = useAlert();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const SHOW_QUERY_KEY = ["coupons", _id];
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const couponFormDrawer = useDrawer(CouponFormDrawer);

  const handleEdit = (couponToEdit) => {
    couponFormDrawer.show({
      cancel() {
        couponFormDrawer.remove();
      },
      data: couponToEdit,
      errorCallback() {
        showAlert({
          message: `An error has ocurred!`,
          severity: "error",
          duration: 10000,
        });
      },
      successCallback() {
        queryClient.invalidateQueries({
          queryKey: ["coupons", couponToEdit.code],
        });
        showAlert({
          message: `Successfully saved changes to coupon`,
        });
        couponFormDrawer.remove();
      },
    });
  };
  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  const coupon = useQuery({
    queryKey: SHOW_QUERY_KEY,
    queryFn: fetchCoupon,
    placeholderData: location.state?.coupon,
  });
  const TITLE = coupon.data?.code || "Loading";
  const FUTURE = isFuture(new Date(coupon.data?.expiration));

  const mutation = useMutation({
    mutationFn() {
      return deleteCoupon(_id);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["coupons"],
      });
      showAlert({ message: "Coupon deleted" });
    },
    onError(error) {
      console.log(error);
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  return (
    <Layout>
      {coupon.isPending ? (
        "Loading"
      ) : coupon.data ? (
        <>
          <TitleBar backRoute={matches ? null : ".."} title={TITLE}>
            <TitleBarButton
              onClick={() => {
                handleEdit(coupon.data);
              }}
              icon="edit"
            >
              Edit
            </TitleBarButton>
            {coupon.data?.status === "valid" &&
              FUTURE &&
              coupon.data.useCount < 1 && (
                <TitleBarButton
                  onClick={() => {
                    setOpenDeleteDialog(true);
                  }}
                  icon="delete"
                >
                  Delete
                </TitleBarButton>
              )}
          </TitleBar>
          {openDeleteDialog ? (
            <UPDialog
              close={handleClose}
              Content="You cannot undo this action."
              Icon={DeleteOutlinedIcon}
              Title="Are you sure you want to permanently delete this coupon?"
              Actions={
                <>
                  <Button
                    color="neutral"
                    onClick={handleClose}
                    variant="outlined"
                  >
                    Never mind
                  </Button>
                  <Button
                    color="error"
                    onClick={() => {
                      mutation.mutate(coupon.data?.code);
                      handleClose();
                      navigate(clientRoutes.coupons.index());
                    }}
                    variant="contained"
                  >
                    Yes, Delete Coupon
                  </Button>
                </>
              }
            />
          ) : null}
          <Layout.Content>
            <CouponDetails coupon={coupon.data} FUTURE={FUTURE} />
          </Layout.Content>
        </>
      ) : (
        "Coupon not found"
      )}
    </Layout>
  );
}

export default CouponShow;
