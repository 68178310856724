import { useInfiniteQuery } from "@tanstack/react-query";
import { capitalize } from "lodash-es";
import { useParams } from "react-router-dom";

import MainDetail from "@/components/MainDetail";
import { STATUS_MAP } from "@/constants";
import clientRoutes from "@/lib/client-routes";
import { fetchAppsByStatus } from "@/models/applications";

import Empty from "./components/Empty";
import SideBar from "./components/SideBar/SideBar";
import ShowApplication from "./show";

const ApplicationsRoute = function () {
  const { status } = useParams();

  const { fetchNextPage, hasNextPage, isFetchingNextPage, ...applications } =
    useInfiniteQuery({
      queryKey: ["application", status],
      queryFn(context) {
        const cursor = context.pageParam;
        return fetchAppsByStatus(status, cursor);
      },
      getNextPageParam: (lastPage) => lastPage.cursor,
      initialPageParam: 0,
    });

  return (
    <MainDetail
      MainComponent={SideBar}
      mainProps={{
        applications,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        supTitle: capitalize(STATUS_MAP[status]),
      }}
      DetailComponent={ShowApplication}
      detailPath={clientRoutes.applications.showPath}
      EmptyComponent={() => <Empty applications={applications} />}
    />
  );
};

export default ApplicationsRoute;
