import { centsToPrice, priceToCents } from "@/lib/helpers";
import requestOptions from "@/lib/request-options";
import routes from "@/lib/server-routes";

import type { TCatalogueItem } from "./types";

/* Helpers
/* ------------------------------------------------------------------ */

function formatForServer(data: TCatalogueItem) {
  return {
    ...data,
    msrp: priceToCents(data.msrp),
  };
}

function formatForClient(data: TCatalogueItem): TCatalogueItem {
  return {
    ...data,
    msrp: centsToPrice(data.msrp),
  };
}

function propertiesOrDefaults(data: Partial<TCatalogueItem>) {
  return {
    ...data,
    metadata: data.metadata || null,
    msrp: data.msrp || "100.00",
    type: data.type || "program",
  };
}

/* Finders
/* ------------------------------------------------------------------ */

const findAllByPartnerID = async function (id: string) {
  const url = routes.catalogue.items(id);
  const resp = await window.auth.client.get(url).json<TCatalogueItem[]>();
  return resp.map(formatForClient);
};

const findByID = async function (id: string) {
  const url = routes.catalogue.show(id);
  const resp = await window.auth.client.get(url).json<TCatalogueItem>();
  return formatForClient(resp);
};

/* Actions
/* ------------------------------------------------------------------ */

async function saveItem(data: TCatalogueItem) {
  const { method, url } = requestOptions("catalogue", data._id);

  const resp = await window.auth.client[method](url, {
    json: formatForServer(data),
  }).json<TCatalogueItem>();

  return formatForClient(resp);
}

const catalogue = {
  findAllByPartnerID,
  findByID,
  propertiesOrDefaults,
  saveItem,
};

export default catalogue;
