import { useMutation } from "@tanstack/react-query";

import { Drawer, DrawerManager } from "@/components/Drawer";
import Catalogue from "@/models/catalogue";

import CatalogueForm from "./Form";

export default DrawerManager.create(function ({
  cancel,
  data,
  errorCallback,
  partners,
  successCallback,
}) {
  const EXISTING_ITEM = Boolean(data?._id);

  const mutation = useMutation({
    mutationFn: Catalogue.saveItem,
    onSuccess(data) {
      if (successCallback) {
        successCallback(data);
      }
    },
    onError(error) {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  });

  return (
    <Drawer
      loading={mutation.isPending}
      title={`${EXISTING_ITEM ? "Edit" : "New"} Catalogue Item`}
    >
      <CatalogueForm
        cancel={cancel}
        data={data}
        isEdit={EXISTING_ITEM}
        isSaving={mutation.isPending}
        onSubmit={(newValues) => {
          mutation.mutate(newValues);
        }}
        partners={partners}
        saveError={mutation.error}
      />
    </Drawer>
  );
});
