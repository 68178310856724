import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  Route,
  Routes,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { useDrawer } from "@/components/Drawer";
import InventoryEditDrawer from "@/components/Drawers/InventoryEditDrawer";
import InventoryNewDrawer from "@/components/Drawers/InventoryNewDrawer";
import EmptyIndexMessage from "@/components/EmptyIndexMessage";
import Layout from "@/components/Layout";
import TitleBar from "@/components/TitleBar";
import TitleBarButton from "@/components/TitleBarButton";
import useAlert from "@/hooks/use-alert";
import clientRoutes from "@/lib/client-routes";
import { serializeData, unSerializeData } from "@/lib/helpers";
import { fetchInventory } from "@/models/inventory";
import { fetchPartners } from "@/models/partner";

import SearchForm from "./components/SearchForm";
import SearchResults from "./components/SearchResults";
import InventorySearchShow from "./show";

const InventorySearchRoute = function () {
  // State ---------------------------------------------------------------------

  const inventoryEditDrawer = useDrawer(InventoryEditDrawer);
  const inventoryNewDrawer = useDrawer(InventoryNewDrawer);
  const showAlert = useAlert();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryParams = unSerializeData(searchParams, [
    "catalogueItem_ids",
    "partner_ids",
  ]);

  // Data Fetching -------------------------------------------------------------

  const partners = useQuery({
    queryKey: ["partners"],
    queryFn: fetchPartners,
  });

  const inventorySearch = useInfiniteQuery({
    queryKey: ["inventory", queryParams],
    queryFn(context) {
      const cursor = context.pageParam;
      return fetchInventory(queryParams, cursor);
    },
    enabled: searchParams.size > 0,
    getNextPageParam(lastPage) {
      return lastPage.cursor;
    },
    initialPageParam: 0,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = inventorySearch;

  useEffect(
    function autoFetch() {
      if (isFetchingNextPage) {
        return;
      }

      if (hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  // Event Handlers ------------------------------------------------------------

  const submitSearch = (data) => {
    const serialData = serializeData(data);
    navigate(
      `${clientRoutes.inventorySearch.index()}?${createSearchParams(
        serialData,
      )}`,
    );
  };

  const handleNew = function () {
    inventoryNewDrawer.show({
      cancel() {
        inventoryNewDrawer.remove();
      },
      partners,
      successCallback(data) {
        showAlert({ message: "Inventory item created!" });

        inventoryNewDrawer.remove();
        navigate(clientRoutes.inventorySearch.show(data._id));
        inventorySearch.refetch();
      },
    });
  };

  const handleEdit = function (data) {
    inventoryEditDrawer.show({
      cancel() {
        inventoryEditDrawer.remove();
      },
      data,
      successCallback() {
        showAlert({
          message: "Inventory item saved",
        });
        inventoryEditDrawer.remove();
        inventorySearch.refetch();
      },
    });
  };

  return (
    <>
      <Layout>
        <>
          <TitleBar title="Inventory">
            <TitleBarButton
              disabled={partners.isLoading}
              icon="add"
              onClick={handleNew}
            >
              {partners.data ? "New Inventory" : "Loading Partners"}
            </TitleBarButton>
          </TitleBar>

          <SearchForm onSubmit={submitSearch} queryParams={queryParams} />

          <div
            style={{
              display: "flex",
              width: "100%",
              flexGrow: 1,
              position: "relative",
            }}
          >
            <Layout.Content>
              <Routes>
                <Route
                  element={
                    <>
                      {searchParams.size > 0 ? (
                        <SearchResults
                          handleEdit={handleEdit}
                          search={inventorySearch}
                          queryParams={queryParams}
                        />
                      ) : (
                        <EmptyIndexMessage text="Perform a search to view a class" />
                      )}
                    </>
                  }
                  index
                />

                <Route
                  element={<InventorySearchShow handleEdit={handleEdit} />}
                  path={clientRoutes.inventorySearch.showPath}
                />
              </Routes>
            </Layout.Content>
          </div>
        </>
      </Layout>
    </>
  );
};

export default InventorySearchRoute;
