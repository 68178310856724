import { useMutation } from "@tanstack/react-query";

import { Drawer, DrawerManager } from "@/components/Drawer";
import { dropEnrollment } from "@/models/orders";

import EnrollmentDropsForm from "./EnrollmentDropsForm";

export default DrawerManager.create(function ({
  cancel,
  data,
  email,
  errorCallback,
  successCallback,
}) {
  const mutation = useMutation({
    mutationFn(data) {
      return dropEnrollment(email, data);
    },
    onSuccess(data) {
      if (successCallback) {
        successCallback(data);
      }
    },
    onError(error) {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  });

  return (
    <Drawer loading={mutation.isPending} title={`Drop Enrollments`}>
      <EnrollmentDropsForm
        data={data}
        cancel={cancel}
        onSubmit={(newValues) => {
          const payload = {
            ...newValues,
            enrollmentData: newValues.enrollmentData.map((enrollmentID) => {
              return data.lineItems.find((item) => item._id === enrollmentID);
            }),
          };
          mutation.mutate(payload);
        }}
        isSaving={mutation.isPending}
      />
    </Drawer>
  );
});
