import { Newspaper } from "phosphor-react";

import EmptyIndexMessage from "@/components/EmptyIndexMessage";

const Empty = function ({ applications }) {
  const isEmpty =
    !applications.isLoading &&
    !applications.isFetching &&
    Boolean(applications.data) &&
    Boolean(applications.data.pages) &&
    ![...applications.data.pages.map((p) => p.results)].flat().length;

  const text = (function () {
    if (applications.isLoading || applications.isFetching) {
      return "Inbox Loading";
    }
    if (isEmpty) {
      return "Inbox Empty!";
    }
    if (Boolean(applications.data?.pages) && applications.data?.pages.length) {
      return "Select an application";
    }

    return "Inbox";
  })();

  return (
    <EmptyIndexMessage
      celebrate={isEmpty}
      icon={<Newspaper size={100} weight="thin" />}
      text={text}
    />
  );
};

export default Empty;
