import { RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";

import CustomCode from "./CustomCode";

const CreateCouponCode = ({ setValue, control, watch }) => {
  const hasCustomCode = watch("hasCustomCode");
  const customCodeSelected = () => {
    setValue("hasCustomCode", true);
    setValue("code", "");
  };

  const autoCodeSelected = () => {
    setValue("hasCustomCode", false);
    setValue("code", "");
  };

  return (
    <Grid container>
      <Grid item xs={5}>
        <Typography gutterBottom variant="body1" fontWeight="500">
          Code
        </Typography>
        <RadioGroup
          control={control}
          value={hasCustomCode}
          component={RadioGroup}
          name="hasCustomCode"
        >
          <FormControlLabel
            value={true}
            label="Custom"
            onChange={customCodeSelected}
            control={<Radio />}
          />
          <FormControlLabel
            onChange={autoCodeSelected}
            value={false}
            label="Auto-generated"
            control={<Radio />}
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={7}>
        <br />
        {hasCustomCode === true ? <CustomCode control={control} /> : null}
      </Grid>
    </Grid>
  );
};

export default CreateCouponCode;
