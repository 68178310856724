import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import TitleBar from "@/components/TitleBar";
import TitleBarButton from "@/components/TitleBarButton";
import clientRoutes from "@/lib/client-routes";

import SideBarList from "./SideBarList";
import CouponSearch from "../CouponSearch";

const SideBar = ({ showNewForm, coupons }) => {
  const couponQueue = coupons.data
    ? coupons.data.map((c) => {
        return c;
      })
    : [];

  // State --------------------------------------------------------------------

  const navigate = useNavigate();

  // Event Handlers -----------------------------------------------------------

  const searchSuccess = (couponCode) => {
    navigate(clientRoutes.coupons.show(couponCode));
  };

  return (
    <Box
      sx={{
        minWidth: "100%",
      }}
    >
      <TitleBar loading={coupons.isLoading} title="Coupons">
        <TitleBarButton icon="add" onClick={showNewForm}>
          New Coupon
        </TitleBarButton>
      </TitleBar>
      <Box
        style={{ overflow: "auto" }}
        sx={{
          p: 2,
          minWidth: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CouponSearch searchSuccess={searchSuccess} />
        <SideBarList coupons={couponQueue} />
      </Box>
    </Box>
  );
};

export default SideBar;
