import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useDrawer } from "@/components/Drawer";
import CouponNewDrawer from "@/components/Drawers/CouponFormDrawer";
import Layout from "@/components/Layout";
import TitleBar from "@/components/TitleBar";
import TitleBarButton from "@/components/TitleBarButton";
import { PS_COUPON_DEFAULTS } from "@/constants";
import useAlert from "@/hooks/use-alert";
import { fetchApplication, saveApplication } from "@/models/applications";
import { fetchProfile } from "@/models/profiles";
import { fetchScholarship } from "@/models/scholarship";

import ShowView from "./components/ShowView";

const ShowApplication = () => {
  const queryClient = useQueryClient();
  const showAlert = useAlert();
  const couponFormDrawer = useDrawer(CouponNewDrawer);
  const params = useParams();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  // Data Fetching -------------------------------------------------------------

  const app = useQuery({
    queryKey: ["applications", params.email, params.partner],
    queryFn: fetchApplication,
    placeholderData: location.state?.application,
  });

  const updateMutation = useMutation({
    mutationFn: saveApplication,
    onSuccess() {
      showAlert({
        message: `Application updated`,
      });
      app.refetch();
      queryClient.invalidateQueries({
        queryKey: ["application"],
      });
    },
    onError(error) {
      console.log(error);
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  const scholarship = useQuery({
    queryKey: ["scholarships", params.email, params.partner],
    queryFn: fetchScholarship,
    placeholderData: location.state?.scholarship ?? null,
  });

  const profile = useQuery({
    queryKey: ["profiles", app.data?.partner._id, app.data?.student.email],
    queryFn: fetchProfile,
    enabled: !!app.data,
  });

  const showCoupon = function () {
    couponFormDrawer.show({
      cancel() {
        couponFormDrawer.remove();
      },
      data: {
        ...PS_COUPON_DEFAULTS,
        partner: { _id: app.data.partner._id, name: app.data.partner.name },
        scopedUsers: [profile.data.email],
      },
      errorCallback(error) {
        if (error.message.includes("409")) {
          showAlert({
            message: `Error! This coupon code already exists!`,
            horizontal: "center",
            severity: "error",
            duration: 10000,
          });
        } else {
          showAlert({
            message: `An error has ocurred!`,
            horizontal: "center",
            severity: "error",
            duration: 10000,
          });
        }
      },
      successCallback(data) {
        showAlert({
          message: `Successfully created coupon with code: ${data.code}`,
          horizontal: "center",
          duration: null,
        });
        couponFormDrawer.remove();
      },
    });
  };

  const TITLE =
    params.email && location.state?.application ? params.email : "Loading";

  return (
    <Layout>
      <>
        <TitleBar backRoute={matches ? null : ".."} title={TITLE}>
          <TitleBarButton onClick={showCoupon} icon="add">
            New Coupon
          </TitleBarButton>
        </TitleBar>
        <Layout.Content>
          <ShowView
            app={app}
            isSaving={updateMutation.isPending}
            profile={profile}
            scholarship={scholarship}
            saveApplication={(newData) => {
              updateMutation.mutate(newData);
            }}
          />
        </Layout.Content>
      </>
    </Layout>
  );
};

export default ShowApplication;
