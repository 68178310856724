import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import Catalogue from "@/models/catalogue";
import { fetchAvailableInventoryForPartner } from "@/models/inventory";

import AddLineItem from "./AddLineItem";

export default function AddItemToCart({
  addToCart,
  cancel,
  cartLineItemIDs,
  isSaving,
  partner,
}) {
  const [selectedCatalogueID, setSelectedCatalogueID] = useState(null);
  const [selectedInventoryItemID, setSelectedInventoryItemID] = useState(null);

  const catalogueItems = useQuery({
    queryKey: ["catalogue", partner._id],
    queryFn() {
      return Catalogue.findAllByPartnerID(partner._id);
    },
    enabled: Boolean(partner._id),
  });

  const catalogueItem = useQuery({
    queryKey: ["inventory", partner._id, selectedCatalogueID],
    queryFn: () => {
      return fetchAvailableInventoryForPartner(
        partner._id,
        selectedCatalogueID,
      );
    },
    enabled: Boolean(selectedCatalogueID),
  });
  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await addToCart({ inventoryItemID: selectedInventoryItemID });
      }}
    >
      <Card elevation={0} variant="outlined">
        <CardContent>
          <Box>
            <Box sx={{ mb: 2 }}>
              {catalogueItems.isLoading ? (
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <CircularProgress size="1.5rem" sx={{ mr: 1.5 }} /> Loading
                  catalogue items
                </Box>
              ) : null}

              {catalogueItems.data ? (
                <FormControl
                  error={false}
                  size="small"
                  sx={{ maxWidth: "100%", minWidth: "200px" }}
                >
                  <InputLabel id="catalogue-item-select-field-label">
                    Catalogue Item
                  </InputLabel>
                  <Select
                    defaultValue=""
                    disabled={isSaving}
                    labelId="catalogue-item-select-field-label"
                    onChange={(e) => {
                      setSelectedCatalogueID(e.target.value);
                      setSelectedInventoryItemID(null);
                    }}
                    size="small"
                  >
                    {catalogueItems.data.map((item) => {
                      return (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                <div />
              )}
            </Box>

            {selectedCatalogueID ? (
              <Box>
                {catalogueItem.isLoading ? (
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <CircularProgress size="1.5rem" sx={{ mr: 1.5 }} /> Loading
                    inventory items
                  </Box>
                ) : null}

                {catalogueItem.data?.inventoryItems ? (
                  <>
                    {catalogueItem.data.inventoryItems.length ? (
                      <FormControl
                        error={false}
                        size="small"
                        sx={{ maxWidth: "100%", minWidth: "200px" }}
                      >
                        <InputLabel id="inventory-item-select-field">
                          Inventory Item
                        </InputLabel>
                        <Select
                          defaultValue=""
                          disabled={isSaving}
                          labelId="inventory-select-field"
                          onChange={(e) => {
                            setSelectedInventoryItemID(e.target.value);
                          }}
                          size="small"
                        >
                          {catalogueItem.data.inventoryItems
                            .filter((item) => {
                              return !cartLineItemIDs.includes(item._id);
                            })
                            .map((item) => {
                              return (
                                <MenuItem key={item._id} value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    ) : (
                      <Typography
                        sx={{
                          border: "1px dashed rgba(153,153,153, 0.6)",
                          borderRadius: 1.5,
                          px: 2,
                          py: 1,
                          opacity: 0.8,
                          fontStyle: "italic",
                        }}
                      >
                        No Inventory items available
                      </Typography>
                    )}
                  </>
                ) : (
                  <div />
                )}
              </Box>
            ) : null}
          </Box>

          {selectedCatalogueID ? (
            <Box sx={{ mt: 4 }}>
              <AddLineItem
                catalogueItem={catalogueItem}
                selectedInventoryItemID={selectedInventoryItemID}
              />
            </Box>
          ) : null}
        </CardContent>
        <CardActions>
          <LoadingButton
            color="primary"
            disabled={!selectedCatalogueID || !selectedInventoryItemID}
            loading={isSaving}
            type="submit"
            variant="text"
          >
            Add to Cart
          </LoadingButton>
          <Button color="neutral" onClick={cancel} variant="text">
            cancel
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}
