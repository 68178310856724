import Stack from "@mui/material/Stack";
import Box from "@mui/system/Box";

import type { ReactNode } from "react";

const Layout = function Layout({
  children,
  sx = {},
}: {
  children: ReactNode;
  sx?: object;
}) {
  return (
    <Stack
      data-test="layout"
      direction="column"
      sx={{
        position: "relative",
        flexGrow: 1,
        maxWidth: "100%",
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};

Layout.Content = function LayoutContent({ children, sx = {} }) {
  return (
    <Box
      data-test="layout-content"
      sx={{
        position: "relative",
        flexGrow: 1,
        pl: 2,
        pr: 2,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Layout;
