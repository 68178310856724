import { Box } from "@mui/system";

export default function DataStripe({ label, content, color }) {
  return (
    <Box
      className="data-stripe"
      sx={{
        flexBasis: "30%",
      }}
    >
      <Box
        sx={{
          width: "4px",
          height: "100%",
          position: "absolute",
          borderRadius: 3,
          left: 0,
          backgroundColor: color,
        }}
      />
      <Box sx={{ opacity: 0.7, fontSize: 15 }}>{label}</Box>
      <Box
        sx={{
          paddingRight: "4px",
          fontSize: 18,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {content}
      </Box>
    </Box>
  );
}
