import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import clientRoutes from "@/lib/client-routes";

const ListItemSX = { borderRadius: 2, paddingLeft: 2 };

const ScholarshipItemButton = function ({ scholarship }) {
  const to = clientRoutes.scholarships.show(
    scholarship.author,
    scholarship.partner._id,
  );
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li>
      <ListItemButton
        component={Link}
        to={to}
        state={{ scholarship }}
        className={match ? "Mui-selected" : ""}
        sx={ListItemSX}
      >
        <ListItemText
          primary={
            <>
              {scholarship.author}
              <br />
              {scholarship.firstName &&
                scholarship.lastName &&
                `${scholarship?.firstName} ${scholarship?.lastName}`}
            </>
          }
          secondary={formatDistanceToNow(new Date(scholarship.createdAt), {
            addSuffix: true,
          })}
          primaryTypographyProps={{
            variant: "body2",
            overflow: "hidden",
            fontWeight: "500",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        />
      </ListItemButton>
    </li>
  );
};

export default ScholarshipItemButton;
