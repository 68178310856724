import { Controller } from "react-hook-form";

import { FormControl, TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";

export default function SetMaxUses({ control }) {
  return (
    <>
      <Typography gutterBottom variant="body1" fontWeight="500">
        Maximum Uses
      </Typography>
      <FormControl margin="normal">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Controller
            control={control}
            name="maxUses"
            render={({ field: { ref, ...rest } }) => {
              return (
                <TextField
                  inputRef={ref}
                  label="Max Uses"
                  required
                  min="1"
                  size="small"
                  type="string"
                  variant="outlined"
                  {...rest}
                  slotProps={{
                    htmlInput: { inputMode: "numeric", pattern: "[0-9]*" },
                  }}
                />
              );
            }}
            rules={{ required: "This field is required" }}
          />
        </div>
      </FormControl>
      <FormHelperText>If left blank no limit will be set</FormHelperText>
    </>
  );
}
