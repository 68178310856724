import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { ActionButtonsView } from "@/components/ActionButtons";
import Spinner from "@/components/Spinner";
import { FORM_PAPER_SX } from "@/constants";
import Catalogue from "@/models/catalogue";
import { adminFetchInventoryByCatalogueID } from "@/models/inventory";

export default function Switch({ cancel, isSaving, lineItem, onSubmit }) {
  const [confirmed, setConfirmed] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      catalogue_item_id: lineItem.catalogueItem._id,
      inventory_id: "",
    },
  });

  const selectedCatalogueID = useWatch({
    control,
    name: "catalogue_item_id",
  });

  const selectedInventoryID = useWatch({
    control,
    name: "inventory_id",
  });

  const formIsValid = selectedCatalogueID && selectedInventoryID;

  const catalogueItems = useQuery({
    queryKey: ["catalogue", lineItem.partner._id],
    queryFn() {
      return Catalogue.findAllByPartnerID(lineItem.partner._id);
    },
    enabled: Boolean(lineItem.partner._id),
  });

  const inventoryItems = useQuery({
    queryKey: ["available-inventory", selectedCatalogueID],
    queryFn() {
      return adminFetchInventoryByCatalogueID(selectedCatalogueID);
    },
    enabled: Boolean(selectedCatalogueID),
  });

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit((formValues) => {
            const nextInventoryItem = inventoryItems.data.find((item) => {
              return item._id === formValues.inventory_id;
            });

            onSubmit({ prevInventoryItem: lineItem, nextInventoryItem });
          })}
          noValidate
        >
          <Paper sx={FORM_PAPER_SX}>
            <Typography fontWeight="bold" variant="overline">
              Current
            </Typography>
            <Typography variant="body1">
              {lineItem.catalogueItem.name}
            </Typography>
            <Typography variant="body1">{lineItem.name}</Typography>
            <br />
            <Typography fontWeight="bold" variant="overline">
              Changing to
            </Typography>
            {catalogueItems.isLoading && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CircularProgress size="1.75rem" sx={{ mr: 2 }} /> Loading
                Catalogue Items
              </Box>
            )}
            {catalogueItems.data && (
              <FormControl
                fullWidth
                error={errors.catalogue_item_id ? true : false}
                margin="normal"
              >
                <InputLabel id="catalogue-item-select-field-label">
                  Catalogue Item
                </InputLabel>
                <Controller
                  control={control}
                  name="catalogue_item_id"
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="catalogue-item-select-field-label"
                      size="small"
                    >
                      {catalogueItems.data.map((item) => {
                        return (
                          <MenuItem key={item._id} value={item._id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  rules={{ required: "This field is required" }}
                />
              </FormControl>
            )}
            {inventoryItems.isLoading && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <CircularProgress size="1.75rem" sx={{ mr: 2 }} /> Loading
                Inventory Items
              </div>
            )}
            {inventoryItems.data && (
              <>
                {!inventoryItems.data.length && (
                  <Typography
                    sx={{
                      border: "1px dashed rgba(153,153,153, 0.6)",
                      marginTop: "0.5rem",
                      borderRadius: 1.5,
                      px: 2,
                      py: 1,
                      opacity: 0.8,
                      fontStyle: "italic",
                    }}
                  >
                    No Inventory items available
                  </Typography>
                )}
                {Boolean(inventoryItems.data.length) && (
                  <FormControl
                    fullWidth
                    error={errors.inventory_id ? true : false}
                    margin="normal"
                  >
                    <InputLabel id="inventory-select-field-label">
                      Inventory Item
                    </InputLabel>
                    <Controller
                      control={control}
                      name="inventory_id"
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="inventory-select-field-label"
                          size="small"
                        >
                          {inventoryItems.data
                            .filter((c) => {
                              return lineItem._id !== c._id;
                            })
                            .map((item) => {
                              return (
                                <MenuItem key={item._id} value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {errors.inventory_id && errors.inventory_id.message}
                  </FormControl>
                )}
              </>
            )}

            <br />
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmed}
                  color="primary"
                  name="conform"
                  onChange={(e) => setConfirmed(e.target.checked)}
                />
              }
              label="My changes look correct"
              labelPlacement="end"
            />

            <br />
          </Paper>

          <ActionButtonsView>
            <Button
              disabled={isSaving}
              onClick={cancel}
              variant="outlined"
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              disabled={isSaving || !formIsValid || !confirmed}
              type="submit"
              variant="contained"
            >
              Perform Switch
            </Button>
            <Spinner loading={isSaving} sx={{ ml: 2 }} />
          </ActionButtonsView>
        </form>
      </div>
    </>
  );
}
